import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import FeaturedImage from '../../../assets/images/cards-about.jpg';
import FeaturedImage1 from '../../../assets/images/cards-games-about.jpg';
import FeaturedImage2 from '../../../assets/images/cards-books-about.jpg';

const styles = {
    cursor: 'pointer',
}

export default function Features() {
    const [currentImage, setCurrentImage] = useState(FeaturedImage)
    return (
        <main>
            <section className="background-11" id="features-17">
                <div className="container">
                    <div className="row justify-content-lg-center align-items-center">
                        <div className="col-lg col-xl-6 pr-lg-4 pr-xl-7">
                            <img className="radius-secondary w-100" src={currentImage} alt="" /></div>
                        <div className="col-lg-4 mt-5 mt-lg-0">
                            <h2>Key App Features</h2>
                            <hr className="short left color-8 mt-5 mb-6 mr-auto" />
                            <div className="row">
                                <div style={styles} onMouseEnter={() => setCurrentImage(FeaturedImage)} className="col-md-6 col-lg-12">
                                    <h5><span className="fas fa-assistive-listening-systems mr-3"></span>Listen to Miss Lainie</h5>
                                    <p className="mt-3">Hear Ms. Lainie read the words, and tell the stories that will help your child learn to read.</p>
                                </div>
                                <div style={styles} onMouseEnter={() => setCurrentImage(FeaturedImage1)} className="col-md-6 col-lg-12 mt-5 mt-md-0 mt-lg-5">
                                    <h5><span className="fas fa-gamepad mr-3"></span>Make learning fun</h5>
                                    <p className="mt-3">The cards will have games you can play making learning fun for your child!</p>
                                </div>
                                <div style={styles} onMouseEnter={() => setCurrentImage(FeaturedImage2)} className="col-md-6 col-lg-12">
                                    <h5 className="mt-5"><span className="fas fa-book-reader mr-3"></span>Fun books to read</h5>
                                    <p className="mt-3">Our app has books designed to help your child remember and associate the word sounds.</p>
                                </div>
                            </div><Link to="/pricing" className="btn btn-primary mt-5 btn-sm">Purchase the App</Link></div>
                    </div>
                </div>
            </section>
        </main>
    )
}
