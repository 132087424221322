import React from 'react'
import MaterialTable from 'material-table'

export default ({data, columns, title}) => {
    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                columns={columns}
                data={data}
                title={title}
            />
        </div>
    )
}