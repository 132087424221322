import React from 'react';
import WithSessionContext from '../../HOC/WithSessionContext';
import { Link } from 'react-router-dom'

const convertTotal = (total) => {
    const t = total.toString();
    return '$' + t.substring(0, t.length - 2) + "." + t.substring(t.length - 2);
}

function Cart(props) {
    const authenticated = props.sessionContext.authenticated
    return (
        <div style={{ marginBottom: '60px' }}>
            <h4>Cart</h4>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">id</th>
                        <th scope="col">Item</th>
                        <th scope="col">Price</th>
                        <th scope="col">Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {props.cart ? props.cart.map(item => {
                        return (
                            <tr key={item.id}>
                                <th scope="row">{item.id}</th>
                                <td>{item.item}</td>
                                <td>{item.price}</td>
                                <td>{item.id === 3002 ? null : <button className="btn btn-danger" style={{ padding: "3px 9px", fontSize: "10px" }} onClick={() => props.deleteItem(item.id)}>x</button>}</td>
                            </tr>
                        )
                    }) : <tr style={{ padding: '20px' }}><td>Your cart is empty</td></tr>}
                    <tr>
                        <th></th>
                        <td></td>
                        <td>total</td>
                        <td>{convertTotal(props.total)}</td>
                    </tr>
                    <tr>
                        <th></th>
                        <td></td>
                        <td></td>
                        <td> {authenticated ? <button onClick={props.showCheckout} className='btn btn-success'>Check Out</button> : <div>You will have to <Link to='/sign-in'>log in</Link> or <Link to='/sign-up'>create an account</Link> to upgrade.</div>}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}


export default WithSessionContext(Cart)