import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import firebaseApp from '../../../Firebase';
import Avatar from '../../../components/Atoms/Avatar';
import Table from '../../../components/Organisms/Table';
import WithSessionContext from '../../../components/HOC/WithSessionContext';
import SiteDialog from '../../../components/Organisms/SiteDialog';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxWidth: '85%',
        margin: '30px auto',
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    hr: {
        background: '#f7f7f7',
        color: '#f7f7f7',
    }
}));
function MyAccount(props) {
    const classes = useStyles();
    const [myInfo, setMyinfo] = useState({});
    const [subs, setSubs] = useState({});
    let [purchases, setPurchases] = useState({});
    let accountStatus;
    try {
        accountStatus = props && props.sessionContext.user && props.sessionContext.user.claims && props.sessionContext.user.claims.accountStatus
    } catch (e) {

    }
    let userId;
    try {
        userId = props.sessionContext.user.claims.user_id
    } catch (e) {

    }

    useEffect(() => {
        const ref = firebaseApp.database().ref(`/users/${userId}`);
        ref.on('value', (snap) => {
            const u = snap.val();
            setMyinfo(u);
            const subs = u && u.stripe && u.stripe.subscriptions;
            let subscriptions
            if (subs) {
                subscriptions = Object.keys(subs).map((sub, i) => {
                    return subs[sub];
                })
                const renewalDate = subscriptions && subscriptions[0].current_period_end && new Date(subscriptions[0].current_period_end * 1000);
                subscriptions[0].renewal_date = renewalDate.toDateString();
                setSubs(subscriptions);
            }
            const purchases = u && u.purchases;

            let purch
            if (purchases) {
                purch = Object.keys(purchases).map((val, i) => {
                    return u.purchases[val]
                })
                setPurchases(purch);
            }
        })
        return () => {
            ref.off('value');
        }
    }, [userId]);

    const updateUser = ({name}) => {
        const ref = firebaseApp.database().ref(`/users/${userId}`)
        ref.update({
            testUpdate: 'New update::::'
        })
        .then(updt => {
            console.log('the update:::::', updt);
        })
    }

    return (
        <div style={{ width: "100%", background: '#f7f7f7', height: 'calc(100vh - 76px)', overflow: 'scroll', }}>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {
                            myInfo.fullName && <Paper className={classes.paper} style={{ textAlign: 'left', paddingTop: '37px' }} >
                                <Grid container spacing={3}>
                                    <Grid item xs={1}>
                                        <Avatar name={myInfo.fullName} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <h4>{myInfo.fullName} <SiteDialog updtUser={updateUser} style={{ fontSize: 10 }} /></h4>
                                    </Grid>
                                </Grid>
                            </Paper>
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {Object.keys(subs).length > 0 ? <Paper className={classes.paper} style={{ textAlign: 'left', paddingTop: '37px' }} >
                            <h4>Subscriptions</h4>
                            {subs.map((subs) => {
                                return (
                                    <div key={subs.id}>
                                        <p>Order Id: {subs.id}</p>
                                        <hr className={classes.hr} />
                                        status: {(subs.status === 'active') && (accountStatus === 'INACTIVE' ) ?
                                            <button onClick={() => {
                                                props.sessionContext.refreshToken()
                                                    .then((idTokenResult) => {
                                                        // Confirm the user is an Admin.
                                                        window.location.reload(true)
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });
                                            }} style={{ backgroundColor: 'red', color: 'white', margin: '5 10' }}>refresh status</button> :
                                            !subs.cancel_at_period_end ? <p style={{ color: 'green' }}> Subscription is active!</p> : null
                                        }
                                        {subs.renewal_date && !subs.cancel_at_period_end && <p>Renewal Date: {subs.renewal_date.toString()}</p>}
                                    {subs.cancel_at_period_end ? <p style={{color: 'red', fontSize: 15}}>Cancellation request accepted, subscription active until: {subs.renewal_date.toString()}</p> : null }
                                    </div>
                                )
                            })}
                        </Paper> : <Paper className={classes.paper} style={{ textAlign: 'left', paddingTop: '37px' }} >No subscriptions to display</Paper>}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {purchases && Object.keys(purchases).length > 0 ? <Paper className={classes.paper} style={{ textAlign: 'left', paddingTop: '37px' }} >
                            <Table
                                data={purchases}
                                title="Orders"
                                columns={[
                                    {
                                        title: 'Order Id',
                                        field: 'orderId'
                                    },
                                    {
                                        title: 'Item',
                                        field: 'item'
                                    },
                                    {
                                        title: 'Amount',
                                        field: 'price'
                                    },
                                    {
                                        title: 'Status',
                                        field: 'status'
                                    },
                                ]}
                            />
                        </Paper> : <Paper className={classes.paper} style={{ textAlign: 'left', paddingTop: '37px' }} >No purchases to display</Paper>}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default WithSessionContext(MyAccount)