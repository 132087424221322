import React from 'react';

const ImageComponent = ({src, alt, width, height, style, click}) => {
	return (
		<img
			src = { src }
			alt = { alt}
			width = { width }
			height = { height }
			style={style}
			onClick={click}
		/>
	)
}

export default ImageComponent;