import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    orangeAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
});

export default function LetterAvatars({name}) {
    const classes = useStyles();
    const arr = name.split(' ');
    let getInitials;    
    if (arr.length > 1) {
        getInitials = `${arr[0][0]}${arr[1][0]}`
    } else {
        getInitials = `${arr[0][0]}`
    }
    return (
        <Grid container justify="center" alignItems="center">
            <Avatar className={classes.orangeAvatar}>{getInitials}</Avatar>
        </Grid>
    );
}
