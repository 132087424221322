import React, { useState, useEffect } from 'react';
/* import styled from 'styled-components'; */

import VideoComponent from '../../Atoms/VideoComponent';
import H1Component from '../../Atoms/h1Component';
import PComponent from '../../Atoms/PComponent';
import ImageComponent from '../../Atoms/ImageComponent';
import VideoDialog from './VideoDialog';

const overlayContainerStyle = {
	'width': '100%',
	'height': '100vh',
}

const overlayStyle = {
	'background': 'rgba(0,0,0,0.4)',
	'textAlign': 'center',
	'color': '#fff',
	'zIndex': 90,
	'position': 'absolute',
	'top': 0,
	'left': 0,
	'width': '100%',
	'height': '100vh',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}

const introVideoSrc = "https://firebasestorage.googleapis.com/v0/b/lainies-frontend.appspot.com/o/lainies-intro.mp4?alt=media&token=bb0f1768-d9bb-40d2-9706-67889f6d1eee";

const videoStyle = {
	'width': '100%',
	'height': '100vh',
	'objectFit': 'cover'
}

const VideoOverlay = (props) => {
	useEffect(() => {
		document.title = "Welcome to Lainie's Learning Lane"
	}, []);

	const [playVideo, setPlayVideo] = useState(false);
	const { src, style } = props && props.data && props.data.image
	return (
		<div className="container-fluid">
			<div className="row" style={{ height: 'calc(100vh - 76.34px)', overflow: 'hidden' }}>
				<div className="col-md-12" style={{padding: 0}}>
					<div style={overlayContainerStyle}>
						<div style={overlayStyle}>

							{props.data && props.data.h1 && <H1Component {...props} />}

							{props.data && props.data.p && <PComponent {...props} />}

							{props && props.data && props.data.image && <ImageComponent src={src} style={style} click={() => setPlayVideo(true)} />}

						</div>

						{props.data &&
							<VideoComponent {...props}
								src={introVideoSrc}
								doesVideoAutoplay={true}
								isVideoMuted={true}
								doesVideoLoop={true}
								videoType={"video/mp4"}
								style={videoStyle}
							/>}

						<VideoDialog open={playVideo} close={() => setPlayVideo(false)} />

					</div>
				</div>
			</div>
		</div>
	)
}

export default VideoOverlay



