import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import Cart from './Cart';
import CheckOutForm from './CheckOutForm';
import CardsUpgrade from '../../../assets/images/add_cards.jpg';
import WithSessionContext from '../../../components/HOC/WithSessionContext';
import { withRouter } from 'react-router-dom'
import { CardElement } from 'react-stripe-elements';
import SimpleDialog from '../../Organisms/SimpleDialog';

import './Stripe.css';
import db from '../../../db';
const iUserState = {
	fullName: '',
	firstName: '',
	lastName: '',
	address1: '',
	address2: '',
	city: '',
	state: '',
	zip: '',
}

const items = {
	3001: {
		id: 3001,
		item: "Physical Cards",
		price: '$15',
		priceT: 1500,
		text: "Add Cards",
		shipping: true,
		buttonText: 'Add Cards'
	},
	3002: {
		id: 3002,
		item: "Full app access",
		price: '$29.99',
		priceT: 2999,
		text: "Unlock App",
		shipping: false,
		buttonText: 'Unlock App'
	}
}

const iCartState = [{
	id: 3002,
	item: "Full app access",
	price: '$29.99',
	priceT: 2999,
	text: "Unlock App",
	shipping: false,
	buttonText: 'Unlock App'
}];

const initState = {
	user: iUserState,
	shipping: iUserState,
	cart: iCartState,
	3001: 'Add Cards',
	3002: 'Unlock App',
	checkOut: false,
	total: 2999,
	userId: null,
	userEmail: null,
	requiresShipping: false,
	showBilling: false,
	showCardInfo: false,
	canSubmit: false,
	openDialog: false,
	dialogInfo: {
		title: '',
		dialogContent: '',
		buttonText: ''
	},
};

class StripePaymentForm extends Component {
	constructor(props) {
		super(props);
		this.state = initState;
		this.submit = this.submit.bind(this);
		this.shippingInfoRef = React.createRef();
		this.cartRef = React.createRef();
		this.billingFormRef = React.createRef();
		this.creditCardFieldRef = React.createRef();
		this.addToCartRef = React.createRef();
	}
	handleChange = (e) => {

		this.setState({
			user: {
				[e.target.name]: e.target.value
			},
		})
	}
	handleShippingChange = (e) => {
		this.setState({
			shipping: {
				...this.state.shipping,
				[e.target.name]: e.target.value
			}
		})
	}
	handleAddToCart = (item, key) => {
		const cart = this.state.cart;
		if (cart.length > 1) {
			this.setState({
				showCart: true,
			})
		}
		let checkItem = cart.findIndex(_item => {
			return item.id === _item.id
		})
		if (checkItem > -1) {
			return;
		}
		let requiresShipping = this.state.requiresShipping;
		if (!requiresShipping) {
			requiresShipping = item.shipping;
		}
		const total = this.state.total + item.priceT;
		cart.push(item);
		this.setState({
			cart,
			[key]: 'In Cart',
			total,
			requiresShipping,
			showCart: true,
		}, () => {
			this.scrollInView(this.cartRef)
		})
	}
	deleteItem = (id) => {
		const cart = this.state.cart;
		let requiresShipping;
		const position = cart.findIndex(item => {
			if (id === item.id && item.shipping) {
				requiresShipping = false;
			}
			return id === item.id
		});
		const total = this.state.total - items[id].priceT;
		cart.splice(position, 1);
		let checkOut = this.state.checkOut;
		if (cart.length < 1) {
			checkOut = false;
		}
		this.setState({
			...cart,
			[id]: items[id].text,
			total,
			checkOut,
			requiresShipping
		})
	}
	scrollInView = (ref) => {
		console.log('el ref::::', ref);
		setTimeout(() => ref.current.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
			inline: 'center',
		}), 10);
	}
	showCheckout = (ref) => {
		this.setState({
			checkOut: true
		}, () => {
			this.scrollInView(ref)
		})
	}
	copyAddress = (e) => {
		e.preventDefault();
		const { user } = this.state
		this.setState({
			shipping: user
		})
	}
	stripeElementChange = (element) => {
		if (!element.empty && element.complete) {
			this.setState({ canSubmit: true });
		}
	}
	submit(e) {
		e.preventDefault();
		const { cart } = this.state
		if (cart.length < 1) {
			this.setState({
				openDialog: true,
				dialogInfo: {
					title: 'Your cart is empty.',
					dialogContent: 'Items in your cart seem to be empty. Please add items before you submit',
					buttonText: 'Close'
				}
			})
			return;
		}
		if (!this.state.canSubmit) {
			this.setState({
				openDialog: true,
				dialogInfo: {
					title: 'Credit Card Information Missing',
					dialogContent: 'We\'re unable to submit your order. Please enter your credit card information.',
					buttonText: 'Close'
				}
			})
		}
		const { fullName } = this.state.user;
		this.setState({
			hideSubmit: true
		})
		let shippingAddress
		if (this.state.requiresShipping) {
			shippingAddress = this.state.shipping;
		}
		const cardInfo = {
			name: fullName
		}
		this.props.stripe.createToken(cardInfo)
			.then(token => {
				const claims = this.props && this.props.sessionContext && this.props.sessionContext.user && this.props.sessionContext.user.claims;
				return db.Users.createSubscription({
					token,
					userId: claims.user_id,
					userEmail: claims.email,
					shippingAddress,
					cart: this.state.cart
				})
			})
			.then(res => {
				if (res.data.success) {
					let iState = initState;
					iState.userId = this.state.userId;
					iState.userEmail = this.state.userEmail;
					this.setState(iState, () => {
						this.setState({
							hideSubmit: false,
							openDialog: true,
							redirect: true,
							dialogInfo: {
								title: 'Successfully upgraded!',
								dialogContent: 'Awesome! You\'ve successfully upgraded the app! The app should automatically refresh in about an hour. You can also hit the refresh status button to unlock the app in the my account page which you will be redirected to after closing this box.',
								buttonText: 'Close'
							}
						})
					});
				} else {
					this.setState({
						hideSubmit: false,
						openDialog: true,
						redirect: false,
						dialogInfo: {
							title: 'Error!',
							dialogContent: res.data.message,
							buttonText: 'Close'
						}
					})
				}
			})
			.catch(e => this.setState({
				hideSubmit: false
			}));

	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.redirect) {
			if (this.state.redirect) {
				this.props.history.push('/account');
			}
		}
		const claims = nextProps && nextProps.sessionContext && nextProps.sessionContext.user && nextProps.sessionContext.user.claims;
		if (Object.keys(this.props.sessionContext.user).length !== Object.keys(nextProps.sessionContext.user).length) {
			if (claims) {
				const { user_id, email } = claims;
				this.setState({
					userId: user_id,
					userEmail: email
				})
			}
			return true;
		}
		return true;
	}
	render() {
		return (
			<section id="forms-2" style={{marginTop: 20}}>
				<div className="container">
					<div className="row justify-content-center py-4" id="formUpgrade">
						<div className="col-6">
							<div style={{background: 'white', padding: 20, borderRadius: 10, color: 'black', marginBottom: 50, marginTop: 20}} className="card align-items-center">
								<div className="card-body text-center">
									<p style={{color: 'black', fontSize: 19}}><span>Our pricing is simple, you can gain full access to the app for $29.99/6 months. Subscriptions automatically renew, and you can cancel anytime. See our full terms here.</span></p>
									<p style={{color: 'black'}}><span style={{fontSize: 26, margin: 33, display: 'block' }}>$29.99/6 months</span></p>
									<button style={{color: 'white', background: 'red', border: 0, '&:hover': {color: 'orange'}}} onClick={() => this.scrollInView(this.addToCartRef)} className="btn btn-success">Upgrade now</button>
								</div>
							</div>
						</div>
						<div className="col-lg-8 text-align-center">
							<h1 style={{ fontSize: '35px', textAlign: 'center', margin: '30px 0 50px' }}>Upgrading is easy!</h1>
							<p style={{ textAlign: 'center', fontSize: 19, }} className="card-text align-items-center">As a thank you for purchasing the app, you can add the physical Slap Cards for a <span style={{ color: 'red', }}>discounted price of only $15</span>, and we will ship them to you!</p>
						</div>
						<div className="col-lg-7" style={{ margin: '50px 0' }}>
							<div className="row align-items-center">
								<div className="col-12" ref={this.cartRef}>
									<div className="card align-items-center">
										<img className="card-img-top" src={CardsUpgrade} alt="Cards" />
										<div className="card-body text-center">
											<h4 ref={this.addToCartRef} className="card-title align-items-center">Add the cards</h4>
											<button style={{ margin: 7 }} onClick={() => this.handleAddToCart(items[3001], "3001")} className="btn btn-primary" disabled={this.state[3001] === 'In Cart' ? true : false}>{this.state[3001]}</button>
											<button style={{ margin: 7 }} onClick={() => this.setState({ showCart: true }, () => this.scrollInView(this.cartRef))} className="btn btn-secondary">Purchase app</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						{this.state.cart.length > 0 && this.state.showCart ? <div ref={this.cartRef} className="col-lg-7">
							<Cart
								cart={this.state.cart}
								deleteItem={this.deleteItem}
								showCheckout={this.state.requiresShipping ? (e) => {
									e.preventDefault();
									this.setState({
										showBilling: true,
										showShipping: true,
									}, () => this.scrollInView(this.shippingInfoRef))
								}
									: (e) => {
										e.preventDefault();
										this.setState({
											showCardInfo: true
										}, () => this.scrollInView(this.creditCardFieldRef))
										return true
									}
								}
								requiresShipping={false}
								total={this.state.total}
							/>
						</div> : null}
						{
							this.state.requiresShipping && this.state.showShipping &&
							<div className="col-lg-7" ref={this.shippingInfoRef}>
								<CheckOutForm
									stripeElementChange={this.stripeElementChange}
									title="Shipping Information"
									data={this.state.shipping}
									handleChange={this.handleShippingChange}
									requiresShipping={this.state.requiresShipping ? (e) => {
										e.preventDefault();
										this.setState({
											showCardInfo: true
										}, () => this.scrollInView(this.creditCardFieldRef))
										return true
									}
										: null
									}
									copyAddress={this.copyAddress}
									submit={this.submit} />
							</div>}

						{this.state.showCardInfo && <div ref={this.creditCardFieldRef} className="col-lg-7">
							<form className="checkOutForm">
								<div className="col-12">
									<h3>Credit Card Information</h3>
									<div className="form-group">
										<div className="form-group"><label className="ls text-uppercase color-5 fw-700 mb-0">Name on card</label><input onChange={this.handleChange} name="fullName" value={`${this.state.user.fullName}`} className="form-control" type="text" placeholder="First Name" required={true} /></div>
										<label className="ls text-uppercase color-5 fw-700 mb-0">Card Information</label>
										<CardElement
											onChange={this.stripeElementChange}
										/>
										<button onClick={this.submit} className="btn btn-success" disabled={this.state.hideSubmit}>Submit</button>
									</div>
								</div>
							</form>
						</div>}
					</div>
				</div>
				<SimpleDialog
					open={this.state.openDialog}
					handleClose={() => {
						this.setState({
							openDialog: false
						})
					}}
					title={this.state.dialogInfo.title}
					dialogContent={this.state.dialogInfo.dialogContent}
					buttonText={this.state.dialogInfo.buttonText}
				/>
			</section>
		);
	}
}

export default injectStripe(WithSessionContext(withRouter(StripePaymentForm)));
