export const marriedCouples = [
    {
        "ch": {
            "topLeft": {
                "word": "chair",
                "sound": "chair.wav"
            },
            "topRight": {
                "word": "cheek",
                "sound": "cheek.wav"
            },
            "bottomLeft": {
                "word": "chin",
                "sound": "chin.wav"
            },
            "bottomRight": {
                "word": "chip",
                "sound": "chip.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "marriedCouples",
            "image": "ch.jpg",
            "key": "ch",

        }
    },
    {
        "kn": {
            "topLeft": {
                "word": "knee",
                "sound": "knee.wav"
            },
            "topRight": {
                "word": "knight",
                "sound": "knight.wav"
            },
            "bottomLeft": {
                "word": "knot",
                "sound": "knot.wav"
            },
            "bottomRight": {
                "word": "know",
                "sound": "know.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "kn.jpg",
            "category": "marriedCouples"
        }
    },
    {
        "ph": {
            "topLeft": {
                "word": "phantom",
                "sound": "phantom.wav"
            },
            "topRight": {
                "word": "phone",
                "sound": "phone.wav"
            },
            "bottomLeft": {
                "word": "phonics",
                "sound": "phonics.wav"
            },
            "bottomRight": {
                "word": "photo",
                "sound": "photo.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ph.jpg",
            "category": "marriedCouples"
        }
    },
    {
        "sh": {
            "topLeft": {
                "word": "shape",
                "sound": "shape.wav"
            },
            "topRight": {
                "word": "share",
                "sound": "share.wav"
            },
            "bottomLeft": {
                "word": "sheep",
                "sound": "sheep.wav"
            },
            "bottomRight": {
                "word": "ship",
                "sound": "ship.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "sh.jpg",
            "category": "marriedCouples"
        }
    },
    {
        "th": {
            "topLeft": {
                "word": "teeth",
                "sound": "teeth.wav"
            },
            "topRight": {
                "word": "that",
                "sound": "that.wav"
            },
            "bottomLeft": {
                "word": "the",
                "sound": "the.wav"
            },
            "bottomRight": {
                "word": "this",
                "sound": "this.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "th.jpg",
            "category": "marriedCouples"
        }
    },
    {
        "wh": {
            "topLeft": {
                "word": "what",
                "sound": "what.wav"
            },
            "topRight": {
                "word": "when",
                "sound": "when.wav"
            },
            "bottomLeft": {
                "word": "where",
                "sound": "where.wav"
            },
            "bottomRight": {
                "word": "white",
                "sound": "white.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "wh.jpg",
            "category": "marriedCouples"
        }
    },
]
export const lincolnHatBestFriends = [
    {
        "ai": {
            topLeft: {
                word: "mail",
                sound: "mail.wav"
            },
            topRight: {
                word: "pail",
                sound: "pail.wav"
            },
            "bottomLeft": {
                word: "snail",
                sound: "snail.wav"
            },
            "bottomRight": {
                word: "sail",
                sound: "sail.wav"
            },
            mainSound: "sound.wav",
            story: "story.wav",
            category: "lincolnHatBestFriends",
            image: "ai.jpg"
        }
    },
    {
        "ay": {
            "topLeft": {
                "word": "clay",
                "sound": "clay.wav"
            },
            "topRight": {
                "word": "day",
                "sound": "day.wav"
            },
            "bottomLeft": {
                "word": "play",
                "sound": "play.wav"
            },
            "bottomRight": {
                "word": "say",
                "sound": "say.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "lincolnHatBestFriends",
            "image": "ay.jpg"
        }
    },
    {
        "oa": {
            "topLeft": {
                "word": "boat",
                "sound": "boat.wav"
            },
            "topRight": {
                "word": "coat",
                "sound": "coat.wav"
            },
            "bottomLeft": {
                "word": "goat",
                "sound": "goat.wav"
            },
            "bottomRight": {
                "word": "soap",
                "sound": "soap.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "oa.jpg",
            "category": "lincolnHatBestFriends"
        }
    },
    {
        "ow": {
            "topLeft": {
                "word": "cow",
                "sound": "cow.wav"
            },
            "topRight": {
                "word": "now",
                "sound": "now.wav"
            },
            "bottomLeft": {
                "word": "snow",
                "sound": "snow.wav"
            },
            "bottomRight": {
                "word": "glow",
                "sound": "glow.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ow.jpg",
            "category": "owlSound"
        }
    },
    {
        "ie": {
            "topLeft": {
                "word": "cried",
                "sound": "cried.wav"
            },
            "topRight": {
                "word": "lie",
                "sound": "lie.wav"
            },
            "bottomLeft": {
                "word": "pie",
                "sound": "pie.wav"
            },
            "bottomRight": {
                "word": "tie",
                "sound": "tie.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ie.jpg",
            "category": "lincolnHatBestFriends"
        }
    },
    {
        "igh": {
            "topLeft": {
                "word": "light",
                "sound": "light.wav"
            },
            "topRight": {
                "word": "might",
                "sound": "might.wav"
            },
            "bottomLeft": {
                "word": "night",
                "sound": "night.wav"
            },
            "bottomRight": {
                "word": "sight",
                "sound": "sight.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "igh.jpg",
            "category": "lincolnHatBestFriends"
        }
    },
    {
        "ea": {
            "topLeft": {
                "word": "eat",
                "sound": "eat.wav"
            },
            "topRight": {
                "word": "meat",
                "sound": "meat.wav"
            },
            "bottomLeft": {
                "word": "seat",
                "sound": "seat.wav"
            },
            "bottomRight": {
                "word": "team",
                "sound": "team.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ea.jpg",
            "category": "lincolnHatBestFriends"
        }
    },
    {
        "ee": {
            "topLeft": {
                "word": "bee",
                "sound": "bee.wav"
            },
            "topRight": {
                "word": "eel",
                "sound": "eel.wav"
            },
            "bottomLeft": {
                "word": "feet",
                "sound": "feet.wav"
            },
            "bottomRight": {
                "word": "see",
                "sound": "see.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "lincolnHatBestFriends",
            "image": "ee.jpg"

        }
    },
    {
        "ei": {
            "topLeft": {
                "word": "ceiling",
                "sound": "ceiling.wav"
            },
            "topRight": {
                "word": "either",
                "sound": "either.wav"
            },
            "bottomLeft": {
                "word": "neither",
                "sound": "neither.wav"
            },
            "bottomRight": {
                "word": "weird",
                "sound": "weird.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "lincolnHatBestFriends",
            "image": "ei.jpg"

        }
    },
];
export const oddBalls = [
    {
        "or": {
            "topLeft": {
                "word": "born",
                "sound": "born.wav"
            },
            "topRight": {
                "word": "corn",
                "sound": "corn.wav"
            },
            "bottomLeft": {
                "word": "fort",
                "sound": "fort.wav"
            },
            "bottomRight": {
                "word": "torn",
                "sound": "torn.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "or.jpg",
            "category": "oddBalls"
        }
    },
    {
        "ar": {
            "topLeft": {
                "word": "bar",
                "sound": "bar.wav"
            },
            "topRight": {
                "word": "car",
                "sound": "car.wav"
            },
            "bottomLeft": {
                "word": "far",
                "sound": "far.wav"
            },
            "bottomRight": {
                "word": "jar",
                "sound": "jar.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "oddBalls",
            "image": "ar.jpg"
        }
    },
    {
        "ew": {
            "topLeft": {
                "word": "chew",
                "sound": "chew.wav"
            },
            "topRight": {
                "word": "few",
                "sound": "few.wav"
            },
            "bottomLeft": {
                "word": "new",
                "sound": "new.wav"
            },
            "bottomRight": {
                "word": "flew",
                "sound": "flew.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ew.jpg",
            "category": "oddBalls"
        }
    },
]
export const lionGrowling = [
    {
        "er": {
            "topLeft": {
                "word": "ever",
                "sound": "ever.wav"
            },
            "topRight": {
                "word": "after",
                "sound": "after.wav"
            },
            "bottomLeft": {
                "word": "germ",
                "sound": "germ.wav"
            },
            "bottomRight": {
                "word": "her",
                "sound": "her.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "lionGrowling",
            "image": "er.jpg"

        }
    },
    {
        "ir": {
            "topLeft": {
                "word": "bird",
                "sound": "bird.wav"
            },
            "topRight": {
                "word": "dirt",
                "sound": "dirt.wav"
            },
            "bottomLeft": {
                "word": "first",
                "sound": "first.wav"
            },
            "bottomRight": {
                "word": "girl",
                "sound": "girl.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ir.jpg",
            "category": "lionGrowling"
        }
    },
    {
        "ur": {
            "topLeft": {
                "word": "burn",
                "sound": "burn.wav"
            },
            "topRight": {
                "word": "fur",
                "sound": "fur.wav"
            },
            "bottomLeft": {
                "word": "hurt",
                "sound": "hurt.wav"
            },
            "bottomRight": {
                "word": "turn",
                "sound": "turn.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ur.jpg",
            "category": "lionGrowling"
        }
    }
];
export const singingGirl = [
    {
        "ou": {
            "topLeft": {
                "word": "about",
                "sound": "about.wav"
            },
            "topRight": {
                "word": "out",
                "sound": "out.wav"
            },
            "bottomLeft": {
                "word": "shout",
                "sound": "shout.wav"
            },
            "bottomRight": {
                "word": "sound",
                "sound": "sound_1.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "singingGirl",
            image: "ou.jpg"
        }
    },
    {
        "ow": {
            "topLeft": {
                "word": "cow",
                "sound": "cow.wav"
            },
            "topRight": {
                "word": "now",
                "sound": "now.wav"
            },
            "bottomLeft": {
                "word": "snow",
                "sound": "snow.wav"
            },
            "bottomRight": {
                "word": "glow",
                "sound": "glow.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ow.jpg",
            "category": "owlSound"
        }
    },
    {
        "au": {
            "topLeft": {
                "word": "august",
                "sound": "august.wav"
            },
            "topRight": {
                "word": "sauce",
                "sound": "sauce.wav"
            },
            "bottomLeft": {
                "word": "haul",
                "sound": "haul.wav"
            },
            "bottomRight": {
                "word": "haunted",
                "sound": "haunted.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "singingGirl",
            "image": "au.jpg"
        }
    },
    {
        "aw": {
            "topLeft": {
                "word": "claw",
                "sound": "claw.wav"
            },
            "topRight": {
                "word": "law",
                "sound": "law.wav"
            },
            "bottomLeft": {
                "word": "paw",
                "sound": "paw.wav"
            },
            "bottomRight": {
                "word": "saw",
                "sound": "saw.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "singingGirl",
            image: "aw.jpg"
        }
    },
    {
        "oi": {
            "topLeft": {
                "word": "boil",
                "sound": "boil.wav"
            },
            "topRight": {
                "word": "join",
                "sound": "join.wav"
            },
            "bottomLeft": {
                "word": "coin",
                "sound": "coin.wav"
            },
            "bottomRight": {
                "word": "oil",
                "sound": "oil.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "singingGirl",
            image: "oi.jpg"
        }
    },
    {
        "oy": {
            "topLeft": {
                "word": "boy",
                "sound": "boy.wav"
            },
            "topRight": {
                "word": "joy",
                "sound": "joy.wav"
            },
            "bottomLeft": {
                "word": "soy",
                "sound": "soy.wav"
            },
            "bottomRight": {
                "word": "toy",
                "sound": "toy.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "singingGirl",
            image: "oy.jpg"
        }
    },
];
export const owlSound = [
    {
        "oo": {
            "topLeft": {
                "word": "food",
                "sound": "food.wav"
            },
            "topRight": {
                "word": "moon",
                "sound": "moon.wav"
            },
            "bottomLeft": {
                "word": "poop",
                "sound": "poop.wav"
            },
            "bottomRight": {
                "word": "soon",
                "sound": "soon.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "oo.jpg",
            "category": "owlSound"
        }
    },
    {
        "ue": {
            "topLeft": {
                "word": "blue",
                "sound": "blue.wav"
            },
            "topRight": {
                "word": "clue",
                "sound": "clue.wav"
            },
            "bottomLeft": {
                "word": "glue",
                "sound": "glue.wav"
            },
            "bottomRight": {
                "word": "true",
                "sound": "true.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ue.jpg",
            "category": "owlSound"
        }
    },
    {
        "ui": {
            "topLeft": {
                "word": "bruise",
                "sound": "bruise.wav"
            },
            "topRight": {
                "word": "cruise",
                "sound": "cruise.wav"
            },
            "bottomLeft": {
                "word": "fruit",
                "sound": "fruit.wav"
            },
            "bottomRight": {
                "word": "suit",
                "sound": "suit.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "image": "ui.jpg",
            "category": "Morphing Power"
        }
    },
];
export const eiyPower = [
    {
        "ce": {
            "topLeft": {
                "word": "ace",
                "sound": "ace.wav"
            },
            "topRight": {
                "word": "cent",
                "sound": "cent.wav"
            },
            "bottomLeft": {
                "word": "center",
                "sound": "center.wav"
            },
            "bottomRight": {
                "word": "cereal",
                "sound": "cereal.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "E-I-Y Power",
            "image": "ce.jpg"
        }
    },
    {
        "ci": {
            "topLeft": {
                "word": "cinamon",
                "sound": "cinamon.wav"
            },
            "topRight": {
                "word": "cirle",
                "sound": "circle.wav"
            },
            "bottomLeft": {
                "word": "city",
                "sound": "city.wav"
            },
            "bottomRight": {
                "word": "circus",
                "sound": "circus.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "E-I-Y Power",
            "image": "ci.jpg"
        }
    },
    {
        "cy": {
            "topLeft": {
                "word": "cycle",
                "sound": "cycle.wav"
            },
            "topRight": {
                "word": "fancy",
                "sound": "fancy.wav"
            },
            "bottomLeft": {
                "word": "juicy",
                "sound": "juicy.wav"
            },
            "bottomRight": {
                "word": "spicy",
                "sound": "spicy.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "E-I-Y Power",
            "image": "cy.jpg"
        }
    },
    {
        "ge": {
            "topLeft": {
                "word": "age",
                "sound": "age.wav"
            },
            "topRight": {
                "word": "cage",
                "sound": "cage.wav"
            },
            "bottomLeft": {
                "word": "page",
                "sound": "page.wav"
            },
            "bottomRight": {
                "word": "gem",
                "sound": "gem.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "E-I-Y Power",
            "image": "ge.jpg"
        }
    },
    {
        "gi": {
            "topLeft": {
                "word": "giant",
                "sound": "giant.wav"
            },
            "topRight": {
                "word": "ginger",
                "sound": "ginger.wav"
            },
            "bottomLeft": {
                "word": "ginormous",
                "sound": "ginormous.wav"
            },
            "bottomRight": {
                "word": "logic",
                "sound": "logic.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "E-I-Y Power",
            "image": "gi.jpg"
        }
    },
    {
        "gy": {
            "topLeft": {
                "word": "biology",
                "sound": "biology.wav"
            },
            "topRight": {
                "word": "energy",
                "sound": "energy.wav"
            },
            "bottomLeft": {
                "word": "gym",
                "sound": "gym.wav"
            },
            "bottomRight": {
                "word": "wedgy",
                "sound": "wedgy.wav"
            },
            "mainSound": "sound.wav",
            "story": "story.wav",
            "category": "E-I-Y Power",
            "image": "gy.jpg"
        }
    },
]