import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import WithSessionContext from '../HOC/WithSessionContext';
import db from '../../db';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabs: {
        color: '#fff',
        background: 'red'
    },
}));

export default WithSessionContext(function SimpleTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const subsUserId = Object.keys(props && props.sessionContext && props.sessionContext.user && props.sessionContext.user.subscrptionStatus)[0];
    const [subStatus] = React.useState({
        subId: subsUserId ? props.sessionContext.user.subscrptionStatus[subsUserId] : null
    })
    const [loading, setLoading] = React.useState(false)
    const [message, setMessage] = React.useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const cancelSubscription = () => {
        setLoading(true);
        const data = {
            subscriptionId: subStatus && subStatus.subId && subStatus.subId.id,
            activationCode: subStatus && subStatus.subId && subStatus.subId.activationCode,
            userId: subStatus && subStatus.subId && subStatus.subId.userId,
            subsUserId,
        }
        return db.Users.cancelSubscription(data)
            .then(m => {
                setLoading(false);
                setMessage(true);
            })
            .catch(e => console.log(e));
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label="Account information">
                    <Tab className={classes.tabs} label="Cancellations" {...a11yProps(0)} />
                    {/* <Tab label="Account" {...a11yProps(1)} /> */}
                    {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
            </AppBar>
            {/* <TabPanel value={value} index={0}>
                <h6>Update your info</h6>
            </TabPanel> */}
            {subStatus && subStatus.subId ? <TabPanel value={value} index={0}>
                <h6>Requesting to cancel your account?</h6>
                <p>Our goal is to make sure you're really happy and enjoying the use of our app! If you're experiencing an issue, reach out to us and we'll make every effort to ensure you're successfully using our app. If you'd like to cancel anyway, please press the button below. </p>
                <Button onClick={() => cancelSubscription()} style={{ textTransform: 'lowercase' }}>cancel account</Button>
                {loading && <div><CircularProgress /></div>}
                {message && <p style={{ color: 'red', fontSize: 12 }}>Updated your account. Please give it an hour or so to take effect. Thank you for being a customer!</p>}
            </TabPanel> : <TabPanel>Your account is set to cancel!</TabPanel>}
            {/* <TabPanel value={value} index={2}>
                Item Three
            </TabPanel> */}
        </div>
    );
})
