import React, { useState, useEffect } from 'react';
import firebaseApp from '../../Firebase';
import WithSessionContext from './WithSessionContext';
import { withRouter, Route, Redirect } from 'react-router-dom';
function PrivateRoute(props) {
    const [state, setState] = useState({})
    const { component: Component, isAuthenticated, ...rest } = props
    const { sessionContext: {
        setSession
    } } = props;
    useEffect(() => {
        firebaseApp.auth().onAuthStateChanged(async (user) => {
            if (user) {
                return await firebaseApp.auth().currentUser.getIdTokenResult()
                    .then((idToken) => {
                        const role = idToken && idToken.claims && idToken.claims.role.trim();
                        if (!role) {
                            throw new Error('The user has no role.');
                        }
                        setState({
                            user: 'authenticated',
                            isAuthenticated: true,
                        });
                        return setSession({ idToken, role, claims: idToken.claims })
                    })
                    .catch(e => console.log('error!', e))
            }
        });
    }, [setSession])
    return (
        <Route
            {...rest}
            render={props =>
                state.isAuthenticated ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/sign-in",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
}
export default withRouter(WithSessionContext(PrivateRoute))
/* export default function RequiresAuth({ component: Component, isAuthenticated, ...rest }) {
    class AuthenticatedComponent extends React.Component {
        state = {
            user: null,
        }

        componentDidMount() {
            const { sessionContext: {
                setSession
            } } = this.props;
            firebaseApp.auth().onAuthStateChanged((user) => {
                if (user) {
                    firebaseApp.auth().currentUser.getIdTokenResult()
                        .then((idToken) => {
                            const role = idToken && idToken.claims && idToken.claims.role.trim();
                            if (!role) {
                                throw new Error('The user has no role.');
                            }
                            this.setState({
                                user: 'authenticated'
                            });
                            setSession({ idToken, role, claims: idToken.claims })
                        })
                        .catch(e => console.log('error!', e))
                } else {
                    this.props.history.push('/')
                }
            });
        }
        PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
            return (
                <Route
                    {...rest}
                    render={props =>
                        isAuthenticated ? (
                            <Component {...props} />
                        ) : (
                                <Redirect
                                    to={{
                                        pathname: "/sign-in",
                                        state: { from: props.location }
                                    }}
                                />
                            )
                    }
                />
            );
        }
        render() {
            return this.PrivateRoute
        }
    }

    return WithSessionContext(withRouter(AuthenticatedComponent))
} */