import React, { Component } from 'react'
import BottomNav from './BottomNav';
import WithSessionContext from '../../../../components/HOC/WithSessionContext';
import TextField from '@material-ui/core/TextField';
import styled from "styled-components";

const RightWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
`
const BookWrapper = styled.div`
    width: 700px;
    margin: auto;
    margin-top: 3vh;
    max-height: 460px;
    position: relative;
    border: 1px solid #f3f3f3;
    overflow: hidden;
`

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            end: 0,
            leftKey: Math.random(),
            rightKey: Math.random(),
        }
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        this.setState({
            end: this.props.currentBook.length - 1,
        })
    }
    handleNewKeys() {
        this.setState({
            keys: Math.random(),
        })
    }
    handleNext() {
        if (this.state.current < this.state.end) {
            const newCurrent = this.state.current + 1;
            this.setState({
                current: newCurrent,
                leftKey: Math.random(),
                rightKey: Math.random(),
            })
        }
    }
    handleBack() {
        if (this.state.current > 0) {
            const newCurrent = this.state.current - 1;
            this.setState({
                current: newCurrent,
                leftKey: Math.random(),
                rightKey: Math.random(),
            })
        }
    }

    render() {
        const { currentBook } = this.props;
        return (
            <div>
                <RightWrapper>
                    <BookWrapper>
                        <div>
                            <div className="flip-2-ver-left-2" key={this.state.leftKey} style={{ minHeight: 450, width: '48.333%', float: 'left', overflow: 'hidden', borderRight: '1px solid #f3f3f3', padding: '.5%' }}><img alt="" src={currentBook[this.state.current].left} /></div>
                            <div className="flip-2-ver-left-2" key={this.state.rightKey} style={{ minHeight: 450, width: '48.333%', float: 'left', overflow: 'hidden', borderLeft: '1px solid #d9cfcf', padding: '.5%' }}><img alt="" src={currentBook[this.state.current].right} /></div>
                        </div>
                    </BookWrapper>
                    <BottomNav handleNext={this.handleNext} handleBack={this.handleBack} />
                    <TextField style={{
                        marginTop: 16,
                        maxHeight: 129,
                        color: 'red',
                        }}
                        inputProps={{ style: {textAlign: 'center'} }}
                        id="outlined-basic" value={"page " + this.state.current + " of " + this.state.end}  label="" variant="outlined" />
                </RightWrapper>
            </div>
        )
    }
}

export const Book = WithSessionContext(Container)