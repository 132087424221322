import React from 'react';

import VideoOverlay from '../../../components/Organisms/VideoOverlay/VideoOverlay';

import PlayButton from '../../../assets/images/play.svg';

const introHeader = 'Teaching your child to read quickly and effectively';
const introParagraph = `Teaching a child to read can be challenging. I invite you to try what we have found to be an excellent approach to reading across all levels. Lainie's Learning Lane created an effective way to allow your child to grasp the core concepts of reading by using visual guides and sounds. Your child will have a blast learning how to read and get their educational foundations on track!`;
//const playButtonImgSrc = '';

const overlayHeaderStyle = {
	'padding': '6% 6% 0 ',
	'fontWeight': 600,
	'fontSize': '2.5rem'
}
const overlayPStyle = {
	'padding': '0 13%',
	'fontSize': '19px',
	'color': '#fff'
}
const playButtonStyle = {
	'maxWidth': '100px'
}
const HomepageData = {
	h1: {
		text: introHeader,
		style: overlayHeaderStyle,
	},
	p: {
		text: introParagraph,
		style: overlayPStyle,
	},
	image: {
		className: '',
		style: playButtonStyle,
		src: PlayButton,
		alt: '',
		width: '',
		height: '',
	}
}

export default function Home() {
	return (
		<VideoOverlay data={HomepageData} />
	)
}
