import React from 'react'


const CheckOutForm = React.forwardRef((props, ref) => {
    const { firstName, lastName, address1, address2, city, state, zip } = props.data
    return (
        <form className="checkOutForm" id="checkOutForm">
            <h3>{props.title}</h3>
            <div className="ml-5"></div>
            <div className="row align-items-center">
                <div className="col">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <div className="form-group"><label className="ls text-uppercase color-5 fw-700 mb-0">First Name</label><input onChange={props.handleChange} name="firstName" value={firstName} className="form-control" type="text" placeholder="First Name" required={true} /></div>
                        </div>
                        <div className="col-6">
                            <div className="form-group"><label className="ls text-uppercase color-5 fw-700 mb-0">Last Name{/* <a className="d-inline-block" href="#"><span className="fa fa-question-circle ml-2 color-8"></span></a> */}</label><input onChange={props.handleChange} name="lastName" value={lastName} className="form-control" type="text" placeholder="Last Name" /></div>
                        </div>
                    </div>
                    <div className="form-group"><label className="ls text-uppercase color-5 fw-700 mb-0">Address 1</label><input onChange={props.handleChange} name="address1" value={address1} className="form-control" type="text" placeholder="Address 1" /></div>
                    <div className="form-group"><label className="ls text-uppercase color-5 fw-700 mb-0">Address 2</label><input onChange={props.handleChange} name="address2" value={address2} className="form-control" type="text" placeholder="Address 2" /></div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-5">
                    <div className="form-group"><label className="ls text-uppercase color-5 fw-700 mb-0">City</label><input onChange={props.handleChange} name="city" value={city} className="form-control" type="text" placeholder="City" required={true} /></div>
                </div>
                <div className="col-3">
                    <div className="form-group"><label className="ls text-uppercase color-5 fw-700 mb-0">State{/* <a className="d-inline-block" href="#"><span className="fa fa-question-circle ml-2 color-8"></span></a> */}</label><input onChange={props.handleChange} name="state" value={state} className="form-control" type="text" placeholder="State" /></div>
                </div>
                <div className="col-3">
                    <div className="form-group"><label className="ls text-uppercase color-5 fw-700 mb-0">Zip Code</label><input onChange={props.handleChange} name="zip" value={zip} className="form-control" type="text" placeholder="Zip code" /></div>
                </div>
            </div>
            <button className="btn btn-danger" onClick={props.requiresShipping}>Next</button>
        </form>
    )
})

export default CheckOutForm
