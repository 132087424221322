export default [
    {
        title: 'Kate Bakes a Cake',
        category: 'Magic E',
        skip: false,
        id: 0,
        cover: 'https://sandbox.drummerboyhosting.com/lainies/books/magic-e-phonics/1.jpg',
    },
    {
        title: 'Kate Bakes a Cake',
        category: 'Magic E',
        skip: true,
        id: 1,
        cover: 'https://sandbox.drummerboyhosting.com/lainies/books/coming-soon.jpg',
    },
    {
        title: 'Kate Bakes a Cake',
        category: 'Magic E',
        id: 2,
        skip: true,
        cover: 'https://sandbox.drummerboyhosting.com/lainies/books/coming-soon.jpg',
    },
    {
        title: 'Kate Bakes a Cake',
        category: 'Magic E',
        skip: true,
        id: 3,
        cover: 'https://sandbox.drummerboyhosting.com/lainies/books/coming-soon.jpg',
    },
]