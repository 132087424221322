import React, { Component } from 'react';
import db from '../../../db/index';
import { Link } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import './styles.css';
/* const BackgroundDiv = styled.div`
    background-image: url(${BgImage});
` */
const INIT_VALUES = {
    fullName: '',
    email: '',
    password: '',
    tos: false,
}
const showMessage = (that, error ) => {
    that.setState({ showLoader: false, success: false, error }, () => {
        setTimeout(() => {
            that.setState({
                error: false,
            })
        }, 3000);
    })
}
export default class SignUpPage extends Component {
    constructor() {
        super();
        this.state = {
            values: INIT_VALUES,
            showLoader: false,
        }
    }
    handleSetValues = (e) => {
        if (e.target.value === 'tos-checked') {
            return this.setState({
                values: {
                    ...this.state.values,
                    [e.target.name]: this.state.values.tos === false ? e.target.value : false   
                }
            })
        }
        this.setState({
            values: {
                ...this.state.values,
                [e.target.name]: e.target.value
            }
        }/* , () => console.log('el state:::::', this.state) */)
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ showLoader: true })
        const userInfo = this.state.values;
        const { fullName, email, password, tos } = this.state.values;
        if (!fullName) {
            return showMessage(this, 'Full Name is a required field');
        }
        if (!email) {
            return showMessage(this, 'Email is a required field');
        }
        if (!password) {
            return showMessage(this, 'Password is a required field');
        }
        if (fullName.length < 4) {
            return showMessage(this, 'Full Name requires at least 4 characters');
        }
        console.log('split::::', fullName.split(" "))
        if (fullName.split(" ").length < 2) {
            return showMessage(this, 'Full Name requires at least one space');
        }
        if (!tos) {
            return showMessage(this, 'Please accept the terms of service')
        }
        db.Users.createNewUser(userInfo)
            .then(res => {
                const errorMessage = res.data.message;
                if (res && res.data && res.data.success === false) {
                    swal("Error", `${errorMessage}`, "error");
                    this.setState({ showLoader: false })
                    throw new Error(res.data.error);
                }
                const newVals = Object.assign({}, INIT_VALUES);
                this.setState({ values: newVals, showLoader: false, success: true }, () => {
                    setTimeout(() => {
                        this.setState({
                            success: false,
                        })
                    }, 3000);
                })
            })
            .catch(err => console.error(err));
    }

    render() {
        return (
            <section className="py-0" id="forms-6" style={{ height: 'calc(100vh - 75px)', backgroundColor: '#d30101', }}>
                {/* <BackgroundDiv className="background-holder overlay overlay-0">
                </BackgroundDiv> */}
                <div className="container">
                    <div className="row h-full align-items-center justify-content-md-center justify-content-lg-start py-6" style={{ height: "calc(100vh - 75px!important" }}>
                        <div className="col-md-10 col-lg-6">
                            <h1 className="fw-300 color-white lh-2">
                                {/*
                            Take a the frustration <br />out of learing to read <br
                                className="d-none d-sm-block" /><span className="fw-600">Lainie's App </span> will <br />help your child learn to read
                            */}
                                Awesome! You're getting closer to helping your child <span className="fw-600">Learn to Read!</span>
                            </h1>
                            {/* <p className="lead color-8 mt-5 pr-md-9">Sign up today and become a member!</p> */}
                        </div>
                        <div className="col-md-10 col-lg-6 col-xl-5 px-lg-5">
                            <div className="background-white radius-secondary p-5">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            onChange={this.handleSetValues}
                                            className="form-control"
                                            type="text"
                                            placeholder="Your Full name"
                                            name="fullName"
                                            value={this.state.values.fullName}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            onChange={this.handleSetValues}
                                            className="form-control"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={this.state.values.email}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            onChange={this.handleSetValues}
                                            className="form-control"
                                            type="password"
                                            placeholder="Password"
                                            name="password"
                                            value={this.state.values.password}
                                            required
                                        /></div>
                                    <div className="form-group">
                                        <div className="fs--1 text-right">
                                            <input name="tos" type='checkbox' value="tos-checked" checked={this.state.values.tos} onChange={this.handleSetValues}></input> I have read and agree to the <Link to="/tos">terms of service.</Link></div>
                                        <button style={{ width: '100%' }} disabled={this.state.showLoader} className="btn btn-danger btn-block mt-3" type="submit"
                                            name="submit">{this.state.showLoader ? 'Registering' : 'Create an account'}</button></div>
                                    {this.state.success && <div style={{ color: 'green', }}>account created successfully!</div>}
                                    {this.state.error && <div style={{ color: 'red', }}>{this.state.error}</div>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
