import React from 'react';

const VideoComponent = ({className, style, doesVideoAutoplay, isVideoMuted, doesVideoLoop, src, videoType, controls}) => {
	return (
		<video 
			className = { className }
			style = { style }
			autoPlay = { doesVideoAutoplay } 
			muted = { isVideoMuted }
			loop = { doesVideoLoop }
			src = { src }
      type = { videoType }
      constrols = {controls}>
    </video>
	)
}

export default VideoComponent;