import React, { Component } from 'react';
import { BrowserRouter as Router, Route, /* Redirect */ } from 'react-router-dom';
import { SessionContextProvider } from './components/HOC/session-context';
import HomePage from './public/pages/Home';
import Authenticate from './public/pages/Authenticate';
import SignUp from './public/pages/SingUp';
import FeaturesPage from './public/pages/FeaturesPage';
import DashBoard from './protected/pages/DashBoard';
import TOS from './protected/pages/TOS';
import Upgrade from './protected/pages/Upgrade';
import MyAccount from './protected/pages/MyAccount';
import NavBar from './components/Organisms/NavBar';
import firebaseApp from './Firebase';
import RequiresAuth from './components/HOC/RequiresAuth';
import { Cards } from './protected/pages/App/Cards';
import { LincolnHatWalkThrough } from './protected/pages/App/Cards/LincolnHatWalkthrough';
import { Books } from './protected/pages/App/Books';
import { Games } from './protected/pages/App/Games';
import { SingleBook } from './protected/pages/App/Books/SingleBook';
import './assets/css/style.css';
import './App.css';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authenticated: false,
			user: {},
		}
	}
	setSession = async (user) => {
		const ref = await firebaseApp.database().ref(`/users/${user.claims.user_id}`)
		ref.on('value', snap => {
			const val = snap.val();
			if (val.stripe) {
				user.subscrptionStatus = val.stripe.subscriptions ? val.stripe.subscriptions : {};
			}
			this.setState({
				user,
				authenticated: true,
			});
		})
	}
	endSession = () => {
		return firebaseApp.auth().signOut()
			.then(() => {
				this.setState({
					user: {},
					authenticated: false
				})
			})
	}
	refreshToken = () => {
		return firebaseApp.auth().currentUser.getIdToken(true)
	}

	render() {
		const { user, authenticated } = this.state;
		let user_id = user && user.claims && user.claims.user_id;
		if (!user_id) {
			user_id = Math.random()
		}
		return (
			<SessionContextProvider key={user_id} value={{
				user,
				authenticated,
				setSession: this.setSession,
				endSession: this.endSession,
				refreshToken: this.refreshToken,
			}}>
				<Router>
					<NavBar user={user} />

					<Route key={1} exact path="/" component={HomePage} />
					<Route key={2} exact path="/sign-in" component={Authenticate} />
					<Route key={3} exact path="/sign-up" component={SignUp} />
					<Route key={4} exact path="/features" component={FeaturesPage} />
					{/* <Route key={5} exact path="/pricing" component={Pricing} /> */}
					<Route key={6} path="/dashboard" isAuthenticated={this.state.authenticated} component={DashBoard} />
					<Route key={13} path="/tos" isAuthenticated={this.state.authenticated} component={TOS} />
					<Route key={8} path="/pricing" isAuthenticated={this.state.authenticated} component={Upgrade} />
					<RequiresAuth key={7} path="/account" isAuthenticated={this.state.authenticated} component={MyAccount} />
					<Route key={9} exact path="/app/cards" isAuthenticated={this.state.authenticated} component={Cards} />
					<Route key={14} exact path="/app/cards/lincoln-hat-walkthrough" isAuthenticated={this.state.authenticated} component={LincolnHatWalkThrough} />
					<Route key={10} path="/app/books" isAuthenticated={this.state.authenticated} component={Books} />
					<Route key={12} path="/app/games" isAuthenticated={this.state.authenticated} component={Games} />
					<Route exact key={11} path="/book/:id" isAuthenticated={this.state.authenticated} component={SingleBook} />
				</Router>
				<div className="text-center p-3">&copy; copyright {new Date().getFullYear()} Lainie's Learning Lane. All rights reserved.</div>
			</SessionContextProvider>
		)
	}
}
export default App