import React from 'react';
import { Link } from 'react-router-dom';
import WithSessionContext from '../../../components/HOC/WithSessionContext';
import Cards from '../../../assets/images/dashboard-cards.jpg'
import Games from '../../../assets/images/dashboard-games.jpg'
import Books from '../../../assets/images/dashboard-books.jpg'
function MainDashboard(props) {
    const isInactiveAccount = props && props.sessionContext && props.sessionContext.user && props.sessionContext.user.claims && props.sessionContext.user.claims.accountStatus !== 'ACTIVE'
    return (
        <main>
            <section className="text-center" id="cta-1">
                <div className="container">
                    <div className="row justify-content-center justify-content-center">
                        <h1>Welcome! Choose the activity you want to do!</h1>
                        <div className="col-lg-3">
                            <React.Fragment><br />
                                <Link style={{ maxWidth: '100%', marginTop: 20, overflow: 'hidden', cursor: 'pointer', backgroundColor: 'white', border: '1px solid #f3f3f3' }} className="btn btn-secondary btn-capsule btn-lg" to="/app/cards">
                                    <img style={{ minWidth: '170px', marginLeft: -43, cursor: 'pointer', }} src={Cards} alt=""></img><br />
                                    <span style={{ width: '100%', cursor: 'pointer', color: '#323232' }}>Slap Cards</span>
                                </Link>
                            </React.Fragment>
                        </div>
                        <div className="col-lg-3">


                            <React.Fragment><br />
                                <Link style={{ maxWidth: '100%', marginTop: 20, overflow: 'hidden', cursor: 'pointer', backgroundColor: 'white', border: '1px solid #f3f3f3' }} className="btn btn-secondary btn-capsule btn-lg" to="/app/books">
                                    <img style={{ minWidth: '170px', marginLeft: -43, cursor: 'pointer', }} src={Books} alt=""></img><br />
                                    <span style={{ width: '100%', cursor: 'pointer', color: '#323232' }}>Books</span>
                                </Link>
                            </React.Fragment>
                        </div>
                        <div className="col-lg-3">


                            <React.Fragment><br />
                                <Link style={{ maxWidth: '100%', marginTop: 20, overflow: 'hidden', cursor: 'pointer', backgroundColor: 'white', border: '1px solid #f3f3f3' }} className="btn btn-secondary btn-capsule btn-lg" to="/app/games">
                                    <img style={{ minWidth: '170px', marginLeft: -43, cursor: 'pointer', }} src={Games} alt=""></img><br />
                                    <span style={{ width: '100%', cursor: 'pointer', color: '#323232' }}>Games</span>
                                </Link>
                            </React.Fragment>
                        </div>
                    </div>
                    {(isInactiveAccount || props.sessionContext.user.claims === undefined) ? <div className="row justify-content-center justify-content-center">
                        <div style={{ marginTop: 30, backgroundColor: 'red', padding: 30, maxWidth: 500, borderRadius: 40}}>
                            <h4 style={{ color: 'white', width: '100%' }}>Thank you for trying out the app!</h4>
                            <p style={{ color: 'white', width: '100%', padding: '0 18%' }}>You're currently using the free version of the app. To gain the full experience, we encourage you to upgrade and begin your young reader's journey!</p>
                            <Link className="btn btn-success btn-capsule btn-lg" to="/pricing">Purchase today!</Link>
                        </div>
                    </div> : null}
                </div>
            </section>
        </main>
    )
}

export default WithSessionContext(MainDashboard)