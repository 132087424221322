import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import SiteTabs from '../SiteTabs';
import SettingsIcon from '@material-ui/icons/Settings';
// const useStyles = makeStyles({
// });

function SimpleDialog(props) {
    //   const classes = useStyles();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };
    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <SiteTabs updtUser={props.updtUser} />
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function SimpleDialogDemo(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" size="small" onClick={handleClickOpen}>
                <SettingsIcon fontSize="small" />
            </Button>
            <SimpleDialog updtUser={props.updtUser}  open={open} cancel onClose={handleClose} />
        </div>
    );
}
