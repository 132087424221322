import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    return (
        <MuiDialogTitle disableTypography>
            {props.onClose ? (
                <IconButton aria-label="close" onClick={props.onClose}>
                    <CloseIcon /> {props.videoTitle}
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default class CustomizedDialogs extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
        }
    }

    render() {
        return (
            <div>
                <Dialog onClose={this.props.handleClose} aria-labelledby="customized-dialog-title" open={this.props.open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.props.onClose} videoTitle={this.props.title}> {this.props.title} </DialogTitle>
                    <DialogContent dividers>
                        {this.props.videoLink ? <iframe title={this.props.videoLink} src={this.props.videoLink} width="440" height="248" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe> : null}
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}