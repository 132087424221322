import React, { Component } from 'react'
import Dialog from './Dialog';
import WithSessionContext from '../../../../components/HOC/WithSessionContext';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const baseDir = "https://sandbox.drummerboyhosting.com/lainies/games/";
const videos = [
    {
        title: 'How to play Slap',
        description: 'Learn to play Slap. A game that will challenge your memory and reading skills!',
        category: 'Game Tutorial',
        image: `${baseDir}/slap-game.jpg`,
        icons: [PlayArrowIcon],
        link: "https://player.vimeo.com/video/389088435?autoplay=1&autopause=0",
        id: 0,
    },
    {
        title: 'How to play Making Words',
        description: 'Learn to play Making Words, a game that teaches you how to blend sounds together to make workds.',
        category: 'Game Tutorial',
        image: `${baseDir}/making-words.jpg`,
        icons: [PlayArrowIcon],
        link: "https://player.vimeo.com/video/397562693?autoplay=1&autopause=0",
        id: 1,
    },
]

class GamesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentVideo: null,
            open: false,
            video: {},
        };
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({
            open: false
        })
    }
    setVideo(videoUrl) {
        const vid = videos[videoUrl];
        this.setState({
            open: true,
            video: vid,
            title: vid.title,
            videoUrl: vid.link
        })
    }
    render() {
        return (
            <div>
                <div style={{
                    flexGrow: 1,
                    width: '55vw',
                    height: '73vh',
                    overflow: 'scroll',
                    margin: '5vw auto 10vw',
                    padding: '5px 30px 30px',
                }}>
                    <Grid container spacing={3}>
                        {videos.map((video, index) => <Grid
                            item
                            xs={6}
                            sm={6}
                            style={{
                                minHeight: '100%',
                                marginBottom: 40,
                            }}
                            key={index}
                        >
                            <Paper
                                onClick={() => this.setVideo(video.id)}
                                elevation={0}
                                style={{
                                    backgroundColor: '#0c88cf',
                                    color: '#fff',
                                    position: 'relative',
                                    textAlign: 'center',
                                    padding: 20,
                                    cursor: 'pointer',
                                    borderRadius: 90,
                                    overflow: 'hidden',
                                    minHeight: '100%',
                                    fontWeight: 'bold',
                                }}
                            >
                                <img
                                    src={video.image}
                                    alt=""
                                    style={{
                                        maxWidth: '100%',
                                        borderRadius: 90,
                                    }}
                                />
                                <h2>{video.title}</h2>
                                <p style={{ fontSize: 16, marginTop: 10, color: 'white', }}>{video.description}</p>
                                <PlayArrowIcon
                                    style={{
                                        position: 'absolute',
                                        top: 'calc(50% - 150px)',
                                        left: 'calc(50% - 50px)',
                                        color: 'rgba(255, 0, 0, 0.32)',
                                        fontSize: 100,
                                    }}
                                />
                                <div style={{
                                    position: 'absolute',
                                    bottom: 20,
                                    textAlign: 'center',
                                    width: '90%',
                                    color: 'white',
                                }}>
                                    {video.icons ? video.icons.map((Icon, i) => <Icon key={i} />) : null}
                                </div>
                            </Paper>
                        </Grid>)}
                    </Grid>
                    <Dialog
                        handleClose={this.handleClose}
                        open={this.state.open}
                        onClose={this.handleClose}
                        videoTitle={this.state.video.title}
                        videoLink={this.state.video.link}
                    />
                </div>
            </div>
        );
    }
}

export const Games = WithSessionContext(GamesComponent)