import React, { Component } from 'react'
import Tour from 'reactour';
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import '../../../../assets/css/animation.css';
import '../../../../assets/css/global.css';
import { lincolnHatBestFriends } from '../Cards/cards';
import file from '../../../../assets/audio/audio-books.mp3'
const AudioFile = new Audio(file);
const theCard = [lincolnHatBestFriends[0]];
console.log('the card', theCard);
const steps = [
    {
        // selector: '.higlightLincolnHat',
        selector: '.vowels',
        content: `When children learn to read, they are taught that every word has a vowel. But vowels behave differently based on their surrounding letters.`,
        title: 'The card'
    },
    {
        selector: '.vowels',
        content: 'Vowels can make a sound and say their name. This becomes difficult to teach and learn. ',
        title: 'The card'
    },
    {
        selector: '.higlightLincolnHat',
        content: 'Lainie\'s Learning Lane has come up with a story and an object that will help children associate and remember that the vowel says it\'s name.',
        title: 'The card'
    },
    {
        selector: '.higlightLincolnHat',
        content: ({ goTo, /* inDOM */ }) => (
            <div>
                Click the button to hear Miss Lainie walk you throught this card. <button onClick={() => {
                    goTo(4)
                    AudioFile.play()
                }}>Play Audio</button>
            </div>),
        title: 'The card'
    },
    {
        selector: '.first-step',
        content: '',
        title: 'The card'
    },
];

const LeftWrapper = styled.div`
    width: 18vw;
    min-height: calc(100vh - 70px);
    float: left;
    border-right: 1px solid #f4f4f4;
    padding: 30px;
    margin-top: 12px;
`
const RightWrapper = styled.div`
    width: 82vw;
    float: left;
    min-height: calc(100vh - 70px);
    position: relative;
`
const CardsWrapper = styled.div`
    max-width: 342px;
    min-height: 430px;
    margin: 80px auto 0;
    position: relative;
`;

const Wrapper = styled.div`
    border: 1px solid #939393;
    position: relative;
    z-index: 10;
    width: 340px;
    min-height: 430px;
    overflow: hidden;
    border-radius: 10px;
    padding: 20px;
    transition: .300s;
    /* Hidden init state */
    opacity: 1;
    display: table;
    background: white;
    box-shadow: 5px 9px 15px -2px rgba(0,0,0,0.75);
`;

const CardHeader = styled.div`
    width: 100%;
    display: block;
    top: 10px;
    left: 10px;
    position: absolute;
`
const CardFooter = styled.div`
    width: 100%;
    display: block;
    bottom: 10px;
    left: 10px;
    position: absolute;
`
const ImgWrapper = styled.div`
    width: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    position: relative;
`
const LincolnHatHighlighter = styled.div`
    position: absolute;
    height: 80px;
    width: 117px;
    top: 60px;
    left: 40px;
    background-color: transparent;
`

const VowelHighlighter = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: transparent;
    z-index: 99999;
`
const Left = styled.div`
    float: left;
    position: relative;
`

const Right = styled.div`
    float: right;
    margin-right: 20px;
`
class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTourOpen: false,
            goTo: null,
        }
    }

    componentDidMount() {

    }
    componentWillUnmount() {
        AudioFile.pause();
        AudioFile.currentTime = 0;
    }
    handleTogglePlay = () => {
        // this.setState({ ...this.state, filePlaying: !this.state.filePlaying });
        /* if (this.state.filePlaying) {
            AudioFile.pause();
            // AudioFile.currentTime = 0;
        } else {
            AudioFile.play();
        } */
    }
    openTour = () => {
        this.setState({
            isTourOpen: true,
            // filePlaying: true,
        })
    }
    closeTour = () => {
        this.setState({
            isTourOpen: false,
        })
    }
    render() {
        return (
            <main style={{ backgroundColor: 'red', }}>
                <Tour
                    steps={steps}
                    isOpen={this.state.isTourOpen}
                    onRequestClose={this.closeTour}
                    goToStep={this.state.goTo}
                />
                <LeftWrapper>
                    {!this.state.filePlaying && <button style={{
                        width: '100%',
                        border: '1px solid #f3f3f3'
                    }} className="btn" onClick={this.openTour}>Begin Tutorial</button>}
                    {this.state.filePlaying && <button style={{
                        width: '100%',
                        border: '1px solid #f3f3f3'
                    }} className="btn" onClick={this.handleTogglePlay}>Stop</button>}
                </LeftWrapper>
                <RightWrapper>
                    <CardsWrapper className="first-step">
                        <CSSTransition
                            in={true}
                            timeout={300}
                            classNames="box"
                            unmountOnExit
                        >
                            {() => {
                                return (<Wrapper>
                                    <CardHeader className='fourth-step'>
                                        <Left>
                                            <VowelHighlighter className="vowels">
                                                <button className="cardWordsButton" >{theCard[0].ai.topLeft.word}</button>
                                            </VowelHighlighter>
                                        </Left>
                                        <Right><button className="cardWordsButton">{theCard[0].ai.topRight.word}</button></Right>
                                    </CardHeader>
                                    <ImgWrapper>
                                        <LincolnHatHighlighter className="higlightLincolnHat" />
                                        <img className='sixth-step' style={{ maxWidth: '100%', cursor: 'pointer' }} src={'https://sandbox.drummerboyhosting.com/lainies/cards/ai/ai.jpg'} alt="" />
                                    </ImgWrapper>
                                    <CardFooter className='fifth-step'>
                                        <Left><button className="cardWordsButton">{theCard[0].ai.bottomLeft.word}</button></Left>
                                        <Right><button className="cardWordsButton">{theCard[0].ai.bottomRight.word}</button></Right>
                                    </CardFooter>
                                    {/* <EarSound className='seventh-step' /> */}
                                </Wrapper>);
                            }}
                        </CSSTransition>
                    </CardsWrapper>
                </RightWrapper>
            </main >
        )
    }
}

export const LincolnHatWalkThrough = Container;