import React from 'react';
import WithSessionContext from '../../../components/HOC/WithSessionContext';

function TOS(props) {
    return (
        <main>
            <section className="text-center" id="cta-1">
                <div className="container">
                    <div className="row justify-content-center justify-content-center">
                        <div className="col-lg-12">
                            <h1>Terms of service</h1>
                            <p>Designs Produced by Us Any design produced/product by us for your use is our intellectual property and may not be used in any other form without our prior written consent.</p>
                            <p>No Liability Subject to applicable law, including with respect to liability for personal injury or non-waivable statutory rights under California Law, in no event shall Lainie’s Learning Lane, LLC or its officers, directors, employees, shareholders or agents (A) be liable to the user with respect to use of the sites, the content or the materials contained in or accessed through the sites (including without limitation any damages caused by or resulting from reliance by a user on any information obtained from Lainie’s Learning Lane LLC) or any damages that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from acts of God, communications failures, theft, destruction, or unauthorized access to Lainie’s Learning Lane LLC’s records, programs or services; (B) bhe liable to the user for any indirect, special, incidental, consequential, punitive or exemplary damages, including, without limitation, damages for loss of goodwill, lost profits, loss, theft or corruption of user information or the inability to use the sites or any of their features. The user’s sole remedy is to cease use of the sites. </p>
                            <p>Intellectual Property, Software, and Content The intellectual property rights in all software and content made available to you on or through this website or app remains the property of Lainie’s Learning Lane LLC and are protected by copyright laws and treaties around the world. All such rights are reserved by Lainie’s Learning Lane LLC. You may store, print and display the content supplied solely for your own personal use. You are not permitted to publish, manipulate, distribute or otherwise reproduce, in any format, any of the content or copies of the content supplied to you or which appears on this website and/or app nor may you use any such content in connection with any business or commercial enterprise. </p>
                            <p>You shall not modify, translate, reverse engineer, decompile, disassemble or create derivative works based on any software or accompanying documentation supplied by Lainie’s Learning Lane LLC.</p>
                            <h2>Price and Payment</h2>
                            <p>The price of Lainie’s Learning Lane LLC products are as quoted on the site/app.</p>
                            <p>Payment for all orders must be made by credit or debit card on the checkout page. We accept payment by most major credit and debit cards.</p>
                            <p>Online payment transactions are subject to validation checks by your card user and we are not responsible if your card issuer declines to authorize payment for any reason.</p>
                            <p>Governing Law; Dispute Resolution You agree that all matters relating to your access to or use of the Site/App, including all disputes, will be governed by the laws of the United States and by the laws of the State of California without regard to its conflicts of laws provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in Contra Costa County, California, and waive any objection to such jurisdiction or venue. The preceding provision regarding the venue does not apply if you are a consumer based in the European Union. If you are a consumer based in the European Union, you may make a claim in the courts of the country where you reside. Any claim under these Terms of Conditions must be brought within three (3) months after the cause of action arises, or such claim or cause of action is barred. No recovery may be sought or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and attorneys’ fees. In the event of any controversy or dispute between Lainie’s Learning Lane LLC and you arising out of or in connection with your use of the Site and/or app, the parties shall attempt, promptly and in good faith, to resolve any such dispute. If we are unable to resolve any such dispute within a reasonable amount of time (not to exceed thirty (30) days), then either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available to them under applicable law. </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default WithSessionContext(TOS)