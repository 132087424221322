import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function AlertDialog({ open, close }) {

    return (
        <Dialog style={{background: 'transparent', padding: 0}}
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent style={{background: 'transparent', padding: 0}}>
                <DialogContentText id="alert-dialog-description" style={{background: 'transparent', padding: 0, overflow: 'hidden', marginBottom: -30}}>
                    <video
                        style={{ maxWidth: 600, background: 'transparent', }}
                        autoPlay={true}
                        muted={false}
                        loop={false}
                        src={"https://firebasestorage.googleapis.com/v0/b/lainies-frontend.appspot.com/o/lainies-intro.mp4?alt=media&token=bb0f1768-d9bb-40d2-9706-67889f6d1eee"}
                        type={"video/mp4"}
                        controls
                        download={false}
                    >
                    </video>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}