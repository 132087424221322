import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import StripePaymentForm from '../../../components/Organisms/StripePaymentForm';

function MainDashboard() {
    return (
        <StripeProvider apiKey="pk_test_np9tCO61Dq1SvfImAWJMowiB">
            <Elements>
                <React.Fragment>
                    <StripePaymentForm />
                </React.Fragment>
            </Elements>
        </StripeProvider>
    )
}

export default MainDashboard