import React from 'react'
/* import { CSSTransition } from "react-transition-group"; */
import styled from "styled-components";
/* import Filter from './images/filter.png';
import CancelButton from './images/cancel.png';
 */
import './CardsFilter.css';

const FilterWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
  position: relative;
  z-index: 999;
`
const FilterContainer = styled.div`
  background: #fff;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: left;
  justify-content: center;
`
/* const FilterImage = styled.img.attrs({
    src: Filter
})`
  max-width: 40px;
  border-radius: 40px;
  cursor: pointer;
` */
/* const FilterImageClose = styled.img.attrs({
    src: CancelButton
})`
  max-width: 40px;
  border-radius: 40px;
  cursor: pointer;
  background: #ffbdbd;
` */

const CatImg = styled.div`

`

export default class CardsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            marriedCouples: false,
            lincolnHatBestFriends: false,
            oddBalls: false,
            lionGrowling: false,
            singingGirl: false,
            owlSound: false,
            eiyPower: false
        }
        this.showPopUp = this.showPopUp.bind(this);
        this.toggleCard = this.toggleCard.bind(this);
    }
    showPopUp() {
        const show = !this.state.show;
        this.setState({
            show
        })
    }
    toggleCard(cardCategory) {
        this.setState({
            [cardCategory]: !this.state[cardCategory]
        })
    }
    render() {
        const marriedCouplesAction = !this.state.marriedCouples ? "add" : "remove";
        const lincolnHatBestFriendsAction = !this.state.lincolnHatBestFriends ? "add" : "remove";
        const oddBallsAction = !this.state.oddBalls ? "add" : "remove";
        const lionGrowlingAction = !this.state.lionGrowling ? "add" : "remove";
        const owlSoundAction = !this.state.owlSound ? "add" : "remove";
        const eiyPowerAction = !this.state.eiyPower ? "add" : "remove";
        const singingGirlAction = !this.state.singingGirl ? "add" : "remove";

        return (
            <FilterWrapper>
                {/*               <CSSTransition
                    in={this.state.show}
                    timeout={300}
                    classNames="filterWrap"
                    unmountOnExit
                > */}
                <FilterContainer>
                    <div>
                        <CatImg className="cardContainer marriedCouples" onClick={() => {
                            this.toggleCard("marriedCouples");
                            this.props.filterCard("marriedCouples", marriedCouplesAction)
                        }}>
                            {this.state.marriedCouples ? <div className="on marriedCouples">on</div> : <div className="off marriedCouples">off</div>}
                            <div style={{marginLeft: 20}}>Married Couples</div>
                        </CatImg>
                    </div>

                    <CatImg className="cardContainer lincolnHatBestFriends" onClick={() => {
                        this.toggleCard("lincolnHatBestFriends");
                        this.props.filterCard("lincolnHatBestFriends", lincolnHatBestFriendsAction)
                    }}>
                        {this.state.lincolnHatBestFriends ? <div className="on lincolnHatBestFriends">on</div> : <div className="off lincolnHatBestFriends">off</div>}
                        <div>Best Friends</div>
                    </CatImg>

                    <CatImg className="cardContainer singingGirl" onClick={() => {
                        this.toggleCard("singingGirl");
                        this.props.filterCard("singingGirl", singingGirlAction)
                    }}>
                        {this.state.singingGirl ? <div className="on">on</div> : <div className="off">off</div>}
                        <div>Singing Girl</div>
                    </CatImg>

                    <CatImg className="cardContainer oddBalls" onClick={() => {
                        this.toggleCard("oddBalls");
                        this.props.filterCard("oddBalls", oddBallsAction)
                    }}>
                        {this.state.oddBalls ? <div className="on oddBalls">on</div> : <div className="off oddBalls">off</div>}
                        <div>Oddballs</div>
                    </CatImg>

                    <CatImg className="cardContainer lionGrowling" onClick={() => {
                        this.toggleCard("lionGrowling");
                        this.props.filterCard("lionGrowling", lionGrowlingAction)
                    }}>
                        {this.state.lionGrowling ? <div className="on">on</div> : <div className="off">off</div>}
                        <div>Lion Growling</div>
                    </CatImg>

                    <CatImg className="cardContainer owlSound" onClick={() => {
                        this.toggleCard("owlSound");
                        this.props.filterCard("owlSound", owlSoundAction)
                    }}>
                        {this.state.owlSound ? <div className="on owlSound">on</div> : <div className="off owlSound">off</div>}
                        <div>Owl Sound</div>
                    </CatImg>

                    <CatImg className="cardContainer eiyPower" onClick={() => {
                        this.toggleCard("eiyPower");
                        this.props.filterCard("eiyPower", eiyPowerAction)
                    }}>
                        {this.state.eiyPower ? <div className="on">on</div> : <div className="off">off</div>}
                        <div>EIY Power</div>
                    </CatImg>

                </FilterContainer>
                {/* </CSSTransition> */}
            </FilterWrapper>
        )

    }
}
