export default (db, fbApp) => ({
    getAllUsers: () => {
        return new Promise((resolve, reject) => {
            db.ref('/users').once('value').then((snapshot) => {
                resolve(snapshot.val());
            });
        });

    },
    getUserById: (userId) => {
        return new Promise((resolve, reject) => {
            db.ref('/users/' + userId).on('value', function (snapshot) {
                const value = snapshot.val();
                resolve(value);
            })
        })
    },
    createNewUser: (values) => {
        let createUser = fbApp.functions().httpsCallable('createNewUser')
        return createUser(values)
    },
    createSubscription: (data) => {
        let createSubscription = fbApp.functions().httpsCallable('purchaseSubscription');
        return createSubscription(data);
    },
    cancelSubscription: (data) => {
        let cancelSubscription = fbApp.functions().httpsCallable('cancelSubscription');
        return cancelSubscription(data);
    }
});