import React from 'react';

const PComponent = (props) => {
	return (
		<p className={ props.data.p.className } style={ props.data.p.style }>
			{ props.data.p.text } 
		</p>
	)
}

export default PComponent;