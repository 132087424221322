import React from 'react';

const H1Component = (props) => {
	return (
		<h1 className={ props.data.h1.className } style={ props.data.h1.style }>
			{ props.data.h1.text }
		</h1>
	)
}

export default H1Component;