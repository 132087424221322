import React, { Component } from 'react'
import WithSessionContext from '../../../../components/HOC/WithSessionContext';
import styled from 'styled-components';
import BookList from './BookList';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const BookWrapper = styled.div`
    width: 80vw;
    margin: auto;
    margin-top: 3vh;
    min-height: 67vh;
    max-height: 67vh;
    position: relative;
    border: 1px solid #f3f3f3;
    overflow: hidden;
    padding: 20px;
`;
const GridContainer = styled(Grid)`
`;
const GridItem = styled(Grid)`
`;

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBook: null,
        }
    }
    render() {
        return (
            <div>
                <BookWrapper>
                    <GridContainer container spacing={3}>
                        <div style={{
                            width: '100%',
                            display: 'block',
                        }}>
                            Learn about the cards and how to read them <Link to="/app/cards/lincoln-hat-walkthrough">Go to tutorial.</Link>.
                        </div>
                        {BookList.map((book) => {
                            return <GridItem key={book.id} item xs={3}>
                                {!book.skip ? <Link to={`/book/${book.id}`}>
                                    <Paper style={{ textAlign: 'center' }}>
                                        <img alt="" style={{ maxWidth: 200 }} src={`${book.cover}`} />
                                    </Paper>
                                </Link> : <Paper style={{ textAlign: 'center' }}>
                                        <img alt="" style={{ maxWidth: 200 }} src={`${book.cover}`} />
                                    </Paper>}
                            </GridItem>
                        })}
                    </GridContainer>
                </BookWrapper>
                {/* {<Book currentBook={this.state.currentBook} />} */}
            </div>
        )
    }
}

export const Books = WithSessionContext(Container)