import React, { Component } from 'react'
import CardsFilter from './CardsFilter';
import Tour from 'reactour'
import { CSSTransition } from "react-transition-group";
import WithSessionContext from '../../../../components/HOC/WithSessionContext';
import styled from "styled-components";
import firebase from '@firebase/app';
import '@firebase/storage';
import * as allCards from './cards';
import '../../../../assets/css/animation.css';
import '../../../../assets/css/global.css';
import LeftArrow from './images/left-arrow.png';
import RightArrow from './images/right-arrow.png';
import HumanEar from './images/human-ear.png';
const steps = [
    {
        selector: '.first-step',
        content: 'Every element in this card is clickable',
        title: 'The card'
    },
    {
        selector: '.second-step',
        content: 'This arrow goes back one card',
        title: 'The card'
    },
    {
        selector: '.third-step',
        content: 'This arrow goes forward one card',
        title: 'The card'
    },
    {
        selector: '.fourth-step',
        content: 'You can click these words to hear Miss Lainie read them',
        title: 'The card'
    },
    {
        selector: '.fifth-step',
        content: 'Same with these words',
        title: 'The card'
    },
    {
        selector: '.sixth-step',
        content: 'Click this image to hear the story that will help you remember the sound the letter combination makes',
        title: 'The card'
    },
    {
        selector: '.seventh-step',
        content: 'Listen to the sound the letter combination makes',
        title: 'The card'
    },
];
const storage = firebase.storage();
// const baseDir = "../public/images/cards/";
const baseDir = "https://sandbox.drummerboyhosting.com/lainies/cards/";
const categories = ['marriedCouples', 'lincolnHatBestFriends', 'singingGirl', 'owlSound', 'lionGrowling', 'oddBalls'];
const cardsArray = [].concat(
    allCards.marriedCouples,
    allCards.lincolnHatBestFriends,
    allCards.oddBalls,
    allCards.lionGrowling,
    allCards.singingGirl
);
const limitCardsArray = [].concat(
    allCards[categories[0]][0],
    allCards[categories[1]][0],
    allCards[categories[2]][0],
    allCards[categories[3]][0],
    allCards[categories[4]][0],
);
const LeftWrapper = styled.div`
    width: 18vw;
    min-height: calc(100vh - 70px);
    float: left;
    border-right: 1px solid #f4f4f4;
    padding: 30px;
    margin-top: 12px;
`
const RightWrapper = styled.div`
    width: 82vw;
    float: left;
    min-height: calc(100vh - 70px);
    position: relative;
`
const CardsWrapper = styled.div`
    max-width: 342px;
    min-height: 430px;
    margin: 80px auto 0;
    position: relative;
`;

const Wrapper = styled.div`
    border: 1px solid #939393;
    position: relative;
    z-index: 10;
    width: 340px;
    min-height: 430px;
    overflow: hidden;
    border-radius: 10px;
    padding: 20px;
    transition: .300s;
    /* Hidden init state */
    opacity: 1;
    display: table;
    background: white;
    box-shadow: 5px 9px 15px -2px rgba(0,0,0,0.75);
`;

const EarSound = styled.img.attrs({
    src: HumanEar,
})`
    position: absolute;
    bottom: 20px;
    right: 144px;
    max-width: 30px;
    cursor: pointer;
`

const CardHeader = styled.div`
    width: 100%;
    display: block;
    top: 10px;
    left: 10px;
    position: absolute;
`
const CardFooter = styled.div`
    width: 100%;
    display: block;
    bottom: 10px;
    left: 10px;
    position: absolute;
`
const ImgWrapper = styled.div`
    width: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
`
const Left = styled.div`
    float: left;
`

const Right = styled.div`
    float: right;
    margin-right: 20px;
`
const ImageNext = styled.img.attrs({
    src: RightArrow,
})`
    position: absolute;
    top: 240px;
    right: -80px;
    max-width: 30px;
    cursor: pointer;
`
const ImagePrev = styled.img.attrs({
    src: LeftArrow,
})`
    position: absolute;
    top: 240px;
    left: -80px;
    max-width: 30px;
    cursor: pointer;
`

let currCard;
let playL;
let playR;
let playbL;
let playbR;
let playStory;
let playMainSound;
class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            current: 0,
            limited: true,
            cardCategory: [],
            image: null,
            isTourOpen: false,
        }
        this.changeNext = this.changeNext.bind(this);
        this.changeBack = this.changeBack.bind(this);
        this.playSound = this.playSound.bind(this);
        this.addFilteredCard = this.addFilteredCard.bind(this);
    }

    componentDidMount() {
        let active;
        try {
            active = this.props.sessionContext.user.claims.accountStatus;
        } catch (error) {
            active = false;
        }
        if (active && active.toLocaleLowerCase() !== 'INACTIVE'.toLocaleLowerCase()) {
            this.setState({
                cardsArray,
                limited: false,
            });
        } else {
            this.setState({
                cardsArray: limitCardsArray,
                limited: true,
            })
        }
    }
    changeNext() {
        if (this.state.currentSound) {
            this.state.currentSound.pause();
        }
        if (this.state.current >= this.state.cardsArray.length - 1) return;
        if (this.state.current < this.state.cardsArray.length - 1) {
            currCard = this.state.current + 1;
        }
        this.setState({ show: !this.state.show }, () => {
            setTimeout(() => this.setState({ current: currCard, show: !this.state.show }), 300)
        })
    }
    addFilteredCard(cardCategory, action) {
        let newCardCategoryArray = this.state.cardCategory;
        if (action === "add") {
            newCardCategoryArray.push(cardCategory);
        } else if (action === "remove") {
            let position = this.state.cardCategory.indexOf(cardCategory);
            if (position > -1 && this.state.cardCategory.length > 0) {
                newCardCategoryArray.splice(position, 1);
            }
        }
        this.setState({
            cardCategory: newCardCategoryArray
        }, () => {
            if (this.state.limited) {
                return alert('This feature is only available for the upgraded version')
            }
            let newCardsArray = [];
            if (this.state.cardCategory.length === 0) {
                newCardsArray = cardsArray;
            } else {
                this.state.cardCategory.forEach(cat => {
                    newCardsArray.push(allCards[cat])
                })
            }
            this.setState({
                current: 0,
                cardsArray: [].concat.apply([], newCardsArray)
            })
        })
    }
    componentWillUnmount() {
        if (this.state.currentSound) {
            this.state.currentSound.pause();
        }
    }
    changeBack() {
        if (this.state.currentSound) {
            this.state.currentSound.pause();
        }
        if (this.state.current === 0) return;
        if (this.state.current >= 0) {
            currCard = this.state.current - 1;
        }
        this.setState({ show: !this.state.show }, () => {
            setTimeout(() => this.setState({ current: currCard, show: !this.state.show }), 300)
        })
    }
    playSound(sound) {
        if (this.state.currentSound) {
            this.state.currentSound.pause();
        }
        this.setState({
            currentSound: sound,
        }, () => this.state.currentSound.play());
    }
    getCardAssest = (type, path, stateValue) => {
        switch (type) {
            case 'image':
                storage
                    .ref(`/cards/${path}`)
                    .getDownloadURL()
                    .then(url => {
                        // console.log("Got download url: ", url);
                        this.setState({
                            stateValue: url
                        })
                    });
                break;
            default:
                return false;
        }
    }
    openTour = () => {
        this.setState({
            isTourOpen: true,
        })
    }
    closeTour = () => {
        this.setState({
            isTourOpen: false,
        })
    }
    render() {
        return (
            <main style={{ backgroundColor: 'red', }}>
                <Tour
                    steps={steps}
                    isOpen={this.state.isTourOpen}
                    onRequestClose={this.closeTour} />
                <LeftWrapper>
                    <button style={{
                        width: '100%',
                        border: '1px solid #f3f3f3'
                    }} className="btn" onClick={this.openTour}>App tutorial</button>
                    <CardsFilter filterCard={this.addFilteredCard} selected={true} />
                </LeftWrapper>
                <RightWrapper>
                    <CardsWrapper className="first-step">
                        <ImagePrev className='second-step' onClick={this.changeBack} />
                        <ImageNext className='third-step' onClick={this.changeNext} />
                        <CSSTransition
                            in={this.state.show}
                            timeout={300}
                            classNames="box"
                            unmountOnExit
                        >
                            {() => {
                                if (!this.state.cardsArray) {
                                    return;
                                }
                                let currCard = this.state.cardsArray[this.state.current];
                                const folder = this.state.cardsArray ? Object.keys(currCard)[0] : [{}];
                                let localUrlToCard = `${baseDir}${folder}`;
                                let img = `${localUrlToCard}/${currCard[folder].image}`;
                                let lSound = `${localUrlToCard}/${currCard[folder].topLeft.sound}`;
                                let rSound = `${localUrlToCard}/${currCard[folder].topRight.sound}`;
                                let blSound = `${localUrlToCard}/${currCard[folder].bottomLeft.sound}`;
                                let brSound = `${localUrlToCard}/${currCard[folder].bottomRight.sound}`;
                                let cardStory = `${localUrlToCard}/${currCard[folder].story}`;
                                let mainSound = `${localUrlToCard}/${currCard[folder].mainSound}`;

                                playL = new Audio(`${lSound}`);
                                playR = new Audio(`${rSound}`);
                                playbL = new Audio(`${blSound}`);
                                playbR = new Audio(`${brSound}`);
                                playStory = new Audio(`${cardStory}`);
                                playMainSound = new Audio(`${mainSound}`)

                                return (<Wrapper>
                                    <CardHeader className='fourth-step'>
                                        <Left><button className="cardWordsButton" onClick={() => this.playSound(playL)}>{currCard[folder].topLeft.word}</button></Left>
                                        <Right><button className="cardWordsButton" onClick={() => this.playSound(playR)}>{currCard[folder].topRight.word}</button></Right>
                                    </CardHeader>
                                    <ImgWrapper>
                                        <img className='sixth-step' onClick={() => this.playSound(playStory)} style={{ maxWidth: '100%', cursor: 'pointer' }} src={img} alt="" />
                                    </ImgWrapper>
                                    <CardFooter className='fifth-step'>
                                        <Left><button className="cardWordsButton" onClick={() => this.playSound(playbL)}>{currCard[folder].bottomLeft.word}</button></Left>
                                        <Right><button className="cardWordsButton" onClick={() => this.playSound(playbR)}>{currCard[folder].bottomRight.word}</button></Right>
                                    </CardFooter>
                                    <EarSound className='seventh-step' onClick={() => this.playSound(playMainSound)} />
                                </Wrapper>);
                            }}
                        </CSSTransition>
                    </CardsWrapper>
                </RightWrapper>
            </main >
        )
    }
}

export const Cards = WithSessionContext(Container);