import React, { Component } from 'react';
import * as firebase from 'firebase';
import firebaseApp from '../../../Firebase';
import { withRouter } from "react-router";
import swal from 'sweetalert';
import isEmail from 'validator/lib/isEmail';
import { Link } from 'react-router-dom';
import WithSessionContext from '../../../components/HOC/WithSessionContext';

import '../../../assets/css/iconsmind/iconsmind.css';
let that;

class Authenticate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            disabled: false,
            submitButtonLabel: "Sign In"
        };
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handlePassChange = this.handlePassChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentDidUpdate(nextProps, nextState){
        if (nextState.redirect) {
            return this.props.history.push(this.state.loc)
        }
        return false
    }
    static getDerivedStateFromProps(nextProps) {
        if (Object.keys(nextProps.sessionContext.user).length) {
            let loc;
            try {
                loc = nextProps.location.state.from.pathname
            }
            catch (e) {
                loc = '/dashboard'
            }
            return {
                redirect: true,
                loc
            }
        }
        return null
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }
    handlePassChange(e) {
        this.setState({ password: e.target.value });
    }
    handleSubmit(e) {
        e.preventDefault();
        this.setState({
            disabled: true,
            submitButtonLabel: "Loading",
        })
        var email = this.state.email.trim();
        var password = this.state.password.trim();
        let that = this;
        if (isEmail(email)) {
            firebaseApp.auth().signInWithEmailAndPassword(email, password)
                .then((user) => {
                    // console.log("AUTHENTICATE USER", user)
                    return firebaseApp.auth().currentUser.getIdTokenResult()
                })
                .then((idToken) => {
                    // console.log("IDTOKEN", idToken)
                    const role = idToken && idToken.claims && idToken.claims.role.trim();
                    if (!role) {
                        throw new Error('The user has no role.');
                    }
                    this.setState({
                        user: 'authenticated'
                    });
                    this.props.sessionContext.setSession({ idToken, role, claims: idToken.claims })
                })
                .then(() => {
                    let loc;
                    try {
                        loc = this.props.location.state.from.pathname
                    }
                    catch (e) {
                        loc = '/dashboard'
                    }
                    // console.log("We are here to redirect.", loc)
                    setTimeout(() => this.props.history.push(loc), 500)
                })
                .catch(function (error) {
                    that.setState({
                        disabled: false,
                        submitButtonLabel: "Sign In",
                    })
                    var errorMessage = error.message;
                    swal("Error", errorMessage, 'error')
                });
        } else {
            this.setState({
                disabled: false,
                submitButtonLabel: "Sign In",
            })
            swal("Error", "Email Address in not valid", 'error');
        }
    }
    handleGoogleSignIn(e) {
        e.preventDefault();
        var provider = new firebase.auth.GoogleAuthProvider();
        firebaseApp.auth().signInWithPopup(provider).then(function (result) {
            var user = result.user;
            // var token = result.credential.accessToken;
            if (user) {
                that.props.history.push('/dashboard');
            }
        }).catch(function (error) {
            var errorMessage = error.message;
            swal("Error", "Google sign in error: " + errorMessage, "error");
        });
    }
    render() {
        return (
            <main style={{backgroundColor: 'rgb(211, 1, 1)'}}>
                <section className="py-0" id="forms-12">
                    <div className="container">
                        <div className="row h-full align-items-center justify-content-center py-6">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="background-white radius-secondary p-5 text-center shadow-lg">
                                    <h2 className="fw-300 font-2">Sign In</h2>
                                    <form className="zform mt-5" onSubmit={this.handleSubmit}>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend"><span className="input-group-text background-transparent"><span
                                                className="icon-Email fs-2"></span></span>
                                            </div>
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                placeholder="Your Email"
                                                aria-label="Email"
                                                value={this.state.email}
                                                onChange={this.handleEmailChange}
                                            />
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend"><span className="input-group-text background-transparent"><span
                                                className="icon-Lock-2 fs-2"></span></span>
                                            </div>
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                aria-label="Password"
                                                value={this.state.password}
                                                onChange={this.handlePassChange}
                                            />
                                        </div>
                                        <div className="fs--1 text-right"><Link to="/sign-up">Create An Account?</Link></div>
                                        <div className="form-group"><input className="btn btn-danger btn-capsule mt-4" type="submit" name="submit"
                                            value={this.state.submitButtonLabel} disabled={false} /></div>
                                        <div className="zform-feedback"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default WithSessionContext(withRouter(Authenticate))