import React, { Component } from 'react'
import WithSessionContext from '../../../../components/HOC/WithSessionContext';
import { withRouter } from 'react-router-dom';
import { Book } from './Book';
import BackDrop from '../../../../components/Organisms/BackDrop';

// console.log('Booklist:::::::', BookList);
class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBook: null,
            loading: true,
        }
    }
    componentDidMount() {
        fetch(`https://us-central1-lainies-frontend.cloudfunctions.net/api/books/${this.props.match.params.id}`)
            .then((resp) => {
                return resp.json()
            })
            .then(resp => {
                if (resp.success) {
                    this.setState({
                        currentBook: resp.book.pages,
                        loading: false,
                    })
                } else if (!resp.success) {
                    this.setState({ loading: false })
                    console.log("Error in getting books");
                    throw new Error('No book was found. Please try again later');
                }
            })
            .catch(e => console.log(`Error: ${e.message}`))
    }
    render() {
        return (
            <div>
                {this.state.currentBook ? <Book currentBook={this.state.currentBook} /> : null}
                <BackDrop status={this.state.loading} />
            </div>
        )
    }
}

export const SingleBook = WithSessionContext(withRouter(Container))