import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/icons/Menu';
import WithSessionContext from '../../HOC/WithSessionContext';
import config from '../../../config';
import LogoImg from '../../../assets/images/logo.png';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
/* import NavMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
 */
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        dropShadow: 'none',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    navContainer: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            listStyleType: 'none',
            display: 'flex!important',
            flexBasis: 'auto',
            alignItems: 'center',
            flexGrow: 1,
        },
    },
    navItem: {
        float: 'left',
        '&hover': {
            background: 'transparent',
        }
    },
    navItemHidden: {
        display: 'none',
    },
    link: {
        padding: 15,
        background: 'none',
        color: '#444!important',
        fontFamily: 'Quicksand'
    },
    navBar: {
        backgroundColor: 'white',
        color: '#444'
    },
    logo: {
        maxWidth: 120,
    },
    ul: {
        margin: '0px auto',
        listStyleType: 'none',
    },
    hamMenu: {
        float: 'right',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
}));

function SiteAppBar(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [authenticated, setAuthenticated] = React.useState(false);
    const [menuType, setMenuType] = React.useState('full')
    /* const [anchorEl, setAnchorEl] = React.useState(null); */

    /* function handleClick(event) {
        setAnchorEl(event.currentTarget);
    } */

    /* function handleClose() {
        setAnchorEl(null);
    } */

    let name;
    let welcomeMessage
    const role = props.sessionContext.user.role;
    const context = props.sessionContext;
    React.useEffect(() => {
        if (context.authenticated && context.user.claims.accountStatus.toLocaleLowerCase() !== 'inactive') {
            setMenuType('short')
        }
    }, [role, context])
    if (props.sessionContext.user.role && !authenticated) {
        setAuthenticated(true);
    }
    if (authenticated && !name) {
        name = props && props.sessionContext && props.sessionContext.user && props.sessionContext.user.claims && props.sessionContext.user.claims.name;
        welcomeMessage = `${name}`
    }
    const toggle = () => {
        setExpanded(!expanded)
    }
    const logOut = () => {
        props.sessionContext.endSession()
            .then(out => {
                setAuthenticated(false)
                window.location.replace('/sign-in')
            })
            .catch(e => console.log(e));
    }
    const goToPage = (to) => {
        props.history.push(to);
    }
    // console.log('users auth?::::::', props.sessionContext)
    return (
        <div className={classes.root}>
            <AppBar className={classes.navBar} position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" disableRipple aria-label="menu">
                        <img src={LogoImg} className={classes.logo} alt="" />
                    </IconButton>
                    {/* <div style={{ flexGrow: 1 }}>
                        <IconButton onClick={handleClick} edge="start" className={classes.hamMenu} color="inherit" aria-label="menu">
                            <Menu />
                        </IconButton>
                    </div> */}
                    <div className={classes.navContainer}>
                        <ul className={classes.ul} >
                            {config.app.nav.authMain.map((nav, i) => {
                                let show = menuType === 'short' && nav.showAuth;
                                if (menuType === 'full') {
                                    show=true;
                                }
                                console.log('======', menuType)
                                return <li className={`${classes.navItem} ${show ? '' : ' ' + classes.navItemHidden}`} key={i}>
                                    <Link className={classes.link} to={nav.path}>{nav.label}</Link>
                                </li>
                            })}
                        </ul>
                        {/*  {props.sessionContext.authenticated ? <ul className={classes.ul}>
                            {config.app.nav.authMain.map((nav, i) => {
                                return <li className={classes.navItem} key={i}>
                                    <Link className={classes.link} to={nav.path}>{nav.label}</Link>
                                </li>
                            })}
                        </ul> : null} */}
                    </div>
                    <div className={classes.navContainer} style={{ flexGrow: 0 }}>
                        {
                            (welcomeMessage &&
                                <React.Fragment>
                                    <ButtonDropdown isOpen={expanded} toggle={toggle}>
                                        <DropdownToggle className="btn btn-danger" style={{ marginTop: 0, color: "#fff" }} caret>
                                            {welcomeMessage}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => goToPage('/account')}>My Account</DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem onClick={logOut}>Log Out</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </React.Fragment>)
                        }
                        <ul className={classes.ul}>
                            {
                                !authenticated ?
                                    config.app.nav.signInOrUp.map((nav, i) => {
                                        const classA = 'btn btn-primary btn-capsule btn-sm';
                                        const classB = 'btn btn-lainies-primary btn-capsule btn-sm';

                                        return <li key={i} className={classes.navItem}>
                                            <Link style={{ marginRight: 10 }} className={i > 0 ? classA : classB} to={nav.path} >{nav.label}</Link>
                                        </li>
                                    }) : null
                            }
                        </ul>
                    </div>
                </Toolbar>
            </AppBar>
            {/* 
            What is this for?
            <NavMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {config.app.nav.authMain.map((nav, i) => {
                    return <MenuItem key={i}><Link onClick={handleClose} style={{ minWidth: '100%', textAlign: 'center' }} className={classes.link} to={nav.path}>{nav.label}</Link></MenuItem>
                })}
                {
                    (welcomeMessage &&
                        <div>
                            <DropdownItem onClick={() => goToPage('/account')}>My Account</DropdownItem>
                            <DropdownItem>Cancel Account</DropdownItem>
                            <DropdownItem onClick={logOut}>Log Out</DropdownItem>
                        </div>
                    )
                }
                {
                    !authenticated && config.app.nav.signInOrUp.map((nav, i) => {
                        const classA = 'btn btn-primary btn-capsule btn-sm';
                        const classB = 'btn btn-secondary btn-capsule btn-sm';
                        return <li key={i} className={classes.navItem} style={{ width: '100%', textAlign: 'center', padding: '10px 0', }}>
                            <Link style={{ marginRight: 10 }} className={i > 0 ? classA : classB} to={nav.path} >{nav.label}</Link>
                        </li>
                    })
                }
            </NavMenu>
            
            */}
        </div>
    );
}

export default WithSessionContext(SiteAppBar)