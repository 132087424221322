import * as firebase from 'firebase';
import "firebase/auth";

const config = {
    apiKey: "AIzaSyBXHlRIl7tRS6Jkbx-vgEWzuN7FJU7b5mo",
    authDomain: "lainies-frontend.firebaseapp.com",
    databaseURL: "https://lainies-frontend.firebaseio.com",
    projectId: "lainies-frontend",
    storageBucket: "lainies-frontend.appspot.com"
};
const firebaseApp = firebase.initializeApp(config);

export default firebaseApp;